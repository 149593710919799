import { theme as ZephyrTheme } from '@air/zephyr';
import { CSSProperties } from 'react';

import fontSizes from './fontSizes';
const { space } = ZephyrTheme;

type ButtonSize = Partial<
  Pick<
    CSSProperties,
    | 'borderWidth'
    | 'height'
    | 'borderRadius'
    | 'paddingRight'
    | 'paddingLeft'
    | 'fontSize'
    | 'letterSpacing'
    | 'fontWeight'
  >
>;

export type ButtonSizeName = 'reset' | 'none' | 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl';

const pxToRem = (value: number | string) => `${parseInt(`${value}`) / 16}rem`;

const buttonSizes: { [key in ButtonSizeName]: ButtonSize } = {
  reset: {
    borderWidth: 0,
  },
  none: {},
  xxs: {
    height: pxToRem(space[24]),
    borderRadius: 1,
    paddingRight: pxToRem(space[8]),
    paddingLeft: pxToRem(space[8]),
    fontSize: fontSizes[12],
    fontWeight: 600,
  },
  xs: {
    height: pxToRem(space[32]),
    borderRadius: 2,
    paddingRight: pxToRem(space[12]),
    paddingLeft: pxToRem(space[12]),
    fontSize: fontSizes[14],
    fontWeight: 600,
  },
  s: {
    height: pxToRem(space[40]),
    borderRadius: 2,
    paddingRight: pxToRem(space[16]),
    paddingLeft: pxToRem(space[16]),
    fontSize: fontSizes[16],
    fontWeight: 600,
  },
  m: {
    height: pxToRem(space[48]),
    borderRadius: 3,
    paddingRight: pxToRem(space[20]),
    paddingLeft: pxToRem(space[20]),
    fontSize: fontSizes[16],
    fontWeight: 600,
  },
  l: {
    height: pxToRem(space[56]),
    borderRadius: 3,
    paddingRight: pxToRem(space[24]),
    paddingLeft: pxToRem(space[24]),
    fontSize: fontSizes[16],
    fontWeight: 600,
  },
  xl: {
    height: pxToRem(space[64]),
    borderRadius: 4,
    paddingRight: pxToRem(space[32]),
    paddingLeft: pxToRem(space[32]),
    fontSize: fontSizes[20],
    fontWeight: 600,
  },
};

export default buttonSizes;

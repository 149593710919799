import { useCallback } from 'react';

import { useAnalyticsUtilities } from './useAnalyticsUtilities';

export const useTrackPlasmicEvent = () => {
  const { track } = useAnalyticsUtilities();

  const trackPlasmicEvent = useCallback(
    (eventName: string, props: any) => {
      track(eventName, props);
    },
    [track],
  );

  return { trackPlasmicEvent };
};

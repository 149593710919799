import { ComponentMeta } from '@plasmicapp/host';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ComponentName } from '../../constants/componentName';
import { PLASMIC_DEFAULT_CLASS_ALL, PLASMIC_DEFAULT_CLASS_FORM } from '../../constants/plasmicDefaultClasses';

export type RouteFormProps = {
  children: ReactNode;
  className?: string;
  route: string;
};

export const RouteForm = ({ children, className, route = '/sign-up' }: RouteFormProps) => {
  const router = useRouter();
  const methods = useForm({
    mode: 'onBlur',
  });

  return (
    <FormProvider {...methods}>
      <form
        className={classNames(
          'air-form',
          'is-sign-up ',
          PLASMIC_DEFAULT_CLASS_ALL,
          PLASMIC_DEFAULT_CLASS_FORM,
          className,
        )}
        onSubmit={methods.handleSubmit((data) => {
          if (data.email) {
            router.push(`${route}?email=${encodeURIComponent(data.email)}`);
          } else {
            router.push(route);
          }
        })}
      >
        {children}
      </form>
    </FormProvider>
  );
};

export const ROUTE_FORM_META: ComponentMeta<RouteFormProps> = {
  importPath: '@air/plasmic',
  name: ComponentName.RouteForm,
  props: {
    children: {
      type: 'slot',
    },
    route: {
      type: 'string',
      defaultValue: '/sign-up',
    },
  },
};

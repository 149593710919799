import { registerComponent, RegisterComponentParams } from '@air/plasmic-core';
import { ComponentMeta } from '@plasmicapp/host';
import * as RadixAccordion from '@radix-ui/react-accordion';
import classNames from 'classnames';

import styles from './AccordionContent.module.css';

const COMPONENT_NAME = 'AccordionContent';

export type AccordionContentProps = Pick<RadixAccordion.AccordionContentProps, 'children' | 'className'>;

export const AccordionContent = ({ children, className }: AccordionContentProps) => {
  return <RadixAccordion.Content className={classNames(className, styles.root)}>{children}</RadixAccordion.Content>;
};

const COMPONENT_META: ComponentMeta<AccordionContentProps> = {
  name: COMPONENT_NAME,
  importPath: '@radix-ui/react-accordion',
  parentComponentName: 'AccordionItem',
  props: {
    children: {
      type: 'slot',
    },
  },
};

export const registerAccordionContent = ({ loader }: Pick<RegisterComponentParams, 'loader'>) => {
  return registerComponent({
    component: AccordionContent,
    loader,
    meta: COMPONENT_META,
  });
};

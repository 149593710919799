import { useCallback } from 'react';

import { useAnalyticsUtilities } from './useAnalyticsUtilities';

export const useTrackCalendarBooking = () => {
  const { track } = useAnalyticsUtilities();

  const trackCalendarBooking = useCallback(
    (params?: any) => {
      track('Calendar Booking', params);
    },
    [track],
  );

  return { trackCalendarBooking };
};

import '@reach/menu-button/styles.css';
import 'normalize.css';
import '../../globals.css';

import { SearchParamsProvider } from '@air/plasmic';
import { TooltipProvider } from '@radix-ui/react-tooltip';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { NextComponentType } from 'next';
import { AppContext } from 'next/app';
import { DefaultSeo } from 'next-seo';
import { ReactNode, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';

import { I18n } from '~/old-marketing/constants/I18n';
import Routes, { blogRoutes, marketingRoutes } from '~/old-marketing/constants/old-routes';
import { blogTheme, marketingTheme, nycCreativesTheme, theme as baseTheme } from '~/old-marketing/constants/theme';
import { usePersistReferral } from '~/utils/useReferral';

const DefaultLayoutComponent = ({ children }: { children: ReactNode }) => <div className="h-full">{children}</div>;

function App({
  Component,
  pageProps,
  router,
}: {
  Component: NextComponentType & { layout?: () => JSX.Element; getLayout?: (page: JSX.Element) => JSX.Element };
  pageProps: { [key: string]: any };
  router: AppContext['router'];
}) {
  const isBlogRoute = blogRoutes.includes(router.pathname);
  const isMarketingRoute = marketingRoutes.includes(router.pathname);
  const isNYCCreativesLandingPage = router.pathname === Routes.blog.nycCreativeCouncil;

  const canonicalURL = `https://air.inc${router.asPath}`;

  const theme = useMemo(
    () => ({
      ...baseTheme,
      ...(isMarketingRoute ? marketingTheme : {}),
      ...(isBlogRoute ? blogTheme : {}),
      ...(isNYCCreativesLandingPage ? nycCreativesTheme : {}),
    }),
    [isBlogRoute, isMarketingRoute, isNYCCreativesLandingPage],
  );

  /** Persistent layouts - https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/ */
  const Layout = Component.layout ?? DefaultLayoutComponent;
  const getLayout = Component.getLayout;

  const MemoizedLayout = useMemo(() => {
    return getLayout ? (
      getLayout(<Component {...pageProps} />)
    ) : (
      <Layout {...pageProps}>
        <Component {...pageProps} />
      </Layout>
    );
  }, [getLayout, Component, pageProps, Layout]);

  // Referral code persistence
  usePersistReferral();

  const ogImages = [
    {
      url: '/assets/air-og-image.png',
      width: 1024,
      height: 512,
      alt: I18n.basePageCardAlt,
    },
  ];

  const pageMeta = pageProps?.plasmicData?.entryCompMetas[0];
  const ogImageFromPlasmic = pageMeta?.pageMetadata?.openGraphImageUrl;

  if (ogImageFromPlasmic) {
    ogImages.splice(0, 1, {
      url: ogImageFromPlasmic,
      width: 1024,
      height: 512,
      alt: 'OG Image Alt',
    });
  }

  return (
    <>
      <DefaultSeo
        titleTemplate="%s / Air"
        description={I18n.basePageDescription}
        canonical={canonicalURL}
        openGraph={{
          type: 'website',
          description: I18n.basePageDescription,
          url: canonicalURL,
          title: I18n.basePageTitle,
          site_name: 'Air',
          images: ogImages,
        }}
        twitter={{
          handle: '@airHQ',
          cardType: 'summary_large_image',
        }}
      />
      <ThemeProvider theme={theme}>
        <SpeedInsights route={router.pathname} />
        <SearchParamsProvider>
          <TooltipProvider>{MemoizedLayout}</TooltipProvider>
        </SearchParamsProvider>
      </ThemeProvider>
    </>
  );
}

export default App;

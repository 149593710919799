import { trackGTMEvent, useTrackCalendarBooking } from '@air/marketing-analytics';
import { logger } from '@air/marketing-logger';
import { noop } from 'lodash';
import { useCallback } from 'react';

import { ChilipiperLeadsource } from '../constants/ChilipiperLeadsource';
import { ChilipiperRouter } from '../constants/ChilipiperRouter';
import { getUTMParameters } from '../utils/getUTMParameters';
import { getUTMSource } from '../utils/getUTMSource';

export const CHILIPIPER_SUB_DOMAIN = 'air-inc';

export type CreateLeadParams = {
  values: { [key in string]: string | undefined };
  onClose?: () => void;
  onError?: () => void;
  onSuccess?: ({ leadsource, leadsourceUrl }: { leadsource?: string; leadsourceUrl?: string }) => void;
};

export type CreateLead = (params: CreateLeadParams) => void;

export type UseChilipiperParams = {
  leadsource?: ChilipiperLeadsource;
  router: string;
};

export type UseChilipiper = (params: UseChilipiperParams) => {
  createLead: CreateLead;
};

export const useChilipiper: UseChilipiper = ({ leadsource, router = ChilipiperRouter['limit-fields-aug-22'] }) => {
  const { trackCalendarBooking } = useTrackCalendarBooking();

  /**
   * This function will create a lead in SFDC through Chilipiper based
   * on the values that is passed over.
   */
  const createLead = useCallback<CreateLead>(
    ({ onClose = noop, onError = noop, onSuccess = noop, values }) => {
      if (typeof window === 'undefined') {
        return logger({
          label: 'Chilipiper',
          message: 'window is not available',
          type: 'error',
        });
      }

      if (!window.ChiliPiper) {
        return logger({
          label: 'Chilipiper',
          message:
            'ChiliPiper script is not loaded on the page. Please make sure `<ChilipiperScript />` is loaded on the page.',
          type: 'error',
        });
      }

      const leadsourceUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

      window.ChiliPiper.submit(CHILIPIPER_SUB_DOMAIN, router || ChilipiperRouter['limit-fields-aug-22'], {
        lead: {
          ...values,
          leadsource,
          leadsourceurl: leadsourceUrl,
          lead_source_details: getUTMSource(window.location.href),
          ...getUTMParameters(window.location.search),
        },
        map: true,
        onClose,
        onError,
        onSuccess: () => {
          trackCalendarBooking(values);

          trackGTMEvent({
            event: 'Calendar booking',
            eventProps: {
              label: 'calendar booking',
              category: 'enterprise',
              leadsource,
              leadsourceurl: leadsourceUrl,
              lead_source_details: getUTMSource(window.location.href),
              ...getUTMParameters(window.location.search),
              ...values,
            },
          });

          onSuccess({ leadsource, leadsourceUrl });
        },
      });
    },
    [leadsource, router, trackCalendarBooking],
  );

  return {
    createLead,
  };
};

import { ComponentMeta, GlobalContextMeta, registerComponent } from '@plasmicapp/host';
import { ComponentType } from 'react';

import { register } from './register';

export type ComponentMap = {
  [key in string]:
    | {
        component: ComponentType<any>;
        meta: ComponentMeta<any>;
        isContext?: boolean;
      }
    | {
        component: ComponentType<any>;
        meta: GlobalContextMeta<any>;
        isContext: true;
      };
};

export type RegisterAllComponentsParams = {
  components?: ComponentMap;
  loader: {
    registerComponent: typeof registerComponent;
  };
  debug?: boolean;
};

export const registerAllComponents = ({ components = {}, loader, debug }: RegisterAllComponentsParams) => {
  if (debug) {
    console.log(components);
  }

  if (components) {
    Object.keys(components).map((key) => {
      const component = components[key] ?? {};

      if (!component) return;

      return register({
        ...component,
        loader,
      });
    });
  }
};

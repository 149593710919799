import { usePlasmicCanvasContext } from '@plasmicapp/host';
import { includes } from 'lodash';
import { useCallback, useMemo } from 'react';

import { ComponentName } from '../constants/componentName';

export const usePlasmicCanvas = () => {
  const info = usePlasmicCanvasContext();

  const isComponent = useMemo(() => {
    let prefixArr: boolean[] = [];

    if (typeof info === 'object' && info.componentName) {
      Object.values(ComponentName).map((prefix) => {
        prefixArr.push(includes(info.componentName, prefix));
      });
    }

    return prefixArr.includes(true);
  }, [info]);

  const isSpecificComponent = useCallback(
    (componentName: ComponentName | string) => {
      if (typeof info === 'object' && info.componentName) {
        return includes(info.componentName, componentName);
      }

      return false;
    },
    [info],
  );

  return {
    info,
    isEditor: info,
    isComponent,
    isSpecificComponent,
  };
};

import { registerComponent } from '@plasmicapp/host';

import { COMPONENT_MAP, ComponentMap } from './constants/componentMap';
import { register } from './utils/register';

export * from './components';
export * from './constants/componentMap';
export * from './constants/componentName';
export * from './contexts/SearchParamsProvider';
export * from './hooks/useBillingCycle';
export * from './hooks/usePlasmicCanvas';

export type RegisterAllComponentsParams = {
  componentMap?: ComponentMap;
  loader: {
    registerComponent: typeof registerComponent;
  };
  debug?: boolean;
};

export const registerAllComponents = ({ componentMap = {}, loader, debug }: RegisterAllComponentsParams) => {
  const components: ComponentMap = { ...COMPONENT_MAP, ...componentMap };

  if (debug) {
    console.log(components);
  }

  if (components) {
    Object.keys(components).map((key) => {
      const component = components[key] ?? {};

      if (!component) return;

      return register({
        ...component,
        loader,
      });
    });
  }
};

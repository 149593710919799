import { ComponentMeta } from '@plasmicapp/host';
import * as RadixTabs from '@radix-ui/react-tabs';
import classNames from 'classnames';
import { useEffect } from 'react';

import { ComponentName } from '../constants/componentName';
import { PLASMIC_DEFAULT_CLASS_ALL, PLASMIC_DEFAULT_CLASS_DIV } from '../constants/plasmicDefaultClasses';
import { TabsProvider, useTabs } from '../contexts/TabsProvider';
import { usePlasmicCanvas } from '../hooks/usePlasmicCanvas';

export type TabsProps = RadixTabs.TabsProps;

const _Tabs = ({ activationMode, children, className, defaultValue, dir, orientation }: TabsProps) => {
  const { isEditor } = usePlasmicCanvas();
  const [tab, setTab] = useTabs();

  useEffect(() => {
    if (isEditor) {
      setTab(defaultValue ?? '');
    }
  }, [defaultValue, isEditor, setTab]);

  return (
    <RadixTabs.Root
      activationMode={activationMode}
      asChild
      defaultValue={defaultValue}
      dir={dir}
      onValueChange={setTab}
      value={isEditor ? defaultValue : tab}
      orientation={orientation}
    >
      <div className={classNames('radix-tabs', PLASMIC_DEFAULT_CLASS_ALL, PLASMIC_DEFAULT_CLASS_DIV, className)}>
        {children}
      </div>
    </RadixTabs.Root>
  );
};

export const Tabs = ({ defaultValue, ...restOfProps }: TabsProps) => {
  return (
    <TabsProvider initialValue={defaultValue}>
      <_Tabs defaultValue={defaultValue} {...restOfProps} />
    </TabsProvider>
  );
};

export const TABS_META: ComponentMeta<TabsProps> = {
  importPath: '@air/plasmic',
  name: ComponentName.Tabs,
  isAttachment: true,
  props: {
    activationMode: {
      displayName: 'Activation Mode',
      description:
        'When automatic, tabs are activated when receiving focus. When manual, tabs are activated when clicked.',
      type: 'choice',
      options: ['automatic', 'manual'],
      defaultValue: 'automatic',
    },
    children: {
      type: 'slot',
    },
    defaultValue: {
      displayName: 'Default Tab Value',
      description:
        'The value of the tab that should be active when initially rendered. Use when you do not need to control the state of the tabs.',
      type: 'string',
      defaultValue: '1',
    },
    dir: {
      displayName: 'Reading Direction',
      description: 'The reading direction of the tabs. If omitted, assumes LTR (left-to-right) reading mode.',
      type: 'choice',
      options: ['ltr', 'rtl'],
      defaultValue: 'ltr',
    },
    orientation: {
      displayName: 'Orientation',
      description: 'The orientation of the component.',
      type: 'choice',
      options: ['horizontal', 'vertical'],
      defaultValue: 'horizontal',
    },
  },
};

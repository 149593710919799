import { ComponentMeta } from '@plasmicapp/host';
import * as RadixDialog from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { ReactNode } from 'react';

import { ComponentName } from '../constants/componentName';
import { usePlasmicCanvas } from '../hooks/usePlasmicCanvas';

export type DialogDescriptionProps = {
  children: ReactNode;
  isVisuallyHidden?: boolean;
};

export const DialogDescription = ({ children, isVisuallyHidden }: DialogDescriptionProps) => {
  const { isSpecificComponent } = usePlasmicCanvas();
  const element = <RadixDialog.Description asChild>{children}</RadixDialog.Description>;

  if (isSpecificComponent(ComponentName.DialogDescription)) {
    return (
      <RadixDialog.Root>
        <RadixDialog.Content>{element}</RadixDialog.Content>
      </RadixDialog.Root>
    );
  }

  if (isVisuallyHidden) {
    return <VisuallyHidden>{element}</VisuallyHidden>;
  }

  return element;
};

export const DIALOG_DESCRIPTION_META: ComponentMeta<DialogDescriptionProps> = {
  importPath: '@air/plasmic',
  parentComponentName: ComponentName.DialogContent,
  name: ComponentName.DialogDescription,
  isAttachment: true,
  props: {
    children: {
      type: 'slot',
    },
    isVisuallyHidden: {
      displayName: 'Is visually hidden?',
      type: 'boolean',
      defaultValue: false,
    },
  },
};

import { ComponentMeta } from '@plasmicapp/host';
import { ReactNode } from 'react';

import { ComponentName } from '../constants/componentName';
import { AssetPositionProvider as _AssetPositionProvider } from '../contexts/AssetPositionProvider';

export type AssetPositionProviderProps = {
  children: ReactNode;
};

export const AssetPositionProvider = ({ children }: AssetPositionProviderProps) => {
  return <_AssetPositionProvider>{children}</_AssetPositionProvider>;
};

export const ASSET_POSITION_PROVIDER_META: ComponentMeta<AssetPositionProviderProps> = {
  importPath: '@air/plasmic',
  name: ComponentName.AssetPositionProvider,
  props: {
    children: 'slot',
  },
};

import { registerComponent, RegisterComponentParams } from '@air/plasmic-core';
import { ComponentMeta } from '@plasmicapp/host';
import * as RadixAccordion from '@radix-ui/react-accordion';

const COMPONENT_NAME = 'AccordionTrigger';

export type AccordionTriggerProps = Pick<RadixAccordion.AccordionTriggerProps, 'children' | 'className'>;

export const AccordionTrigger = ({ children, className }: AccordionTriggerProps) => {
  return <RadixAccordion.Trigger className={className}>{children}</RadixAccordion.Trigger>;
};

const COMPONENT_META: ComponentMeta<AccordionTriggerProps> = {
  name: COMPONENT_NAME,
  importPath: '@radix-ui/react-accordion',
  parentComponentName: 'AccordionHeader',
  props: {
    children: {
      type: 'slot',
    },
  },
};

export const registerAccordionTrigger = ({ loader }: Pick<RegisterComponentParams, 'loader'>) => {
  return registerComponent({
    component: AccordionTrigger,
    loader,
    meta: COMPONENT_META,
  });
};

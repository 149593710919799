import { ComponentMeta } from '@plasmicapp/host';
import { ReactNode, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { usePlasmicCanvas } from '../../hooks';
import { SliderProvider, useSlider } from './contexts/SliderContext';

export const SLIDER_NAME = 'Slider';

export type SliderProps = {
  autoplay?: boolean;
  children?: ReactNode;
  className?: string;
  defaultValue: string;
};

const _Slider = ({ autoplay, children, className }: SliderProps) => {
  const { isEditor } = usePlasmicCanvas();
  const { ref, inView } = useInView();
  const { activeSlide, setActiveSlide, slides } = useSlider();

  useEffect(() => {
    if (inView && autoplay && !isEditor) {
      const sliderTimer = setInterval(() => {
        const numberOfSlides = slides.length;
        const currentSlideIndex = slides.indexOf(activeSlide);
        const nextSlideIndex = (currentSlideIndex + 1) % numberOfSlides;
        const isLastSlide = nextSlideIndex === 0;

        if (isLastSlide) {
          setActiveSlide(slides[0]);
        } else {
          setActiveSlide(slides[nextSlideIndex]);
        }
      }, 5200);

      return () => {
        clearInterval(sliderTimer);
      };
    }
  }, [activeSlide, autoplay, isEditor, inView, setActiveSlide, slides]);

  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  );
};

export const Slider = ({ autoplay, children, className, defaultValue }: SliderProps) => {
  return (
    <SliderProvider value={{ activeSlide: defaultValue, slides: [] }}>
      <_Slider autoplay={autoplay} className={className} defaultValue={defaultValue}>
        {children}
      </_Slider>
    </SliderProvider>
  );
};

export const SLIDER_META: ComponentMeta<SliderProps> = {
  name: SLIDER_NAME,
  importPath: '@air/plasmic',
  props: {
    children: 'slot',
    autoplay: {
      type: 'boolean',
    },
    defaultValue: {
      type: 'string',
    },
  },
};

import { registerComponent, RegisterComponentParams, usePlasmicCanvas } from '@air/plasmic-core';
import { ComponentMeta } from '@plasmicapp/host';
import * as RadixAccordion from '@radix-ui/react-accordion';

const COMPONENT_NAME = 'AccordionDebugger';

export type AccordionDebuggerProps = Pick<RadixAccordion.AccordionSingleProps, 'children' | 'value'> & {
  componentName?: string;
};

export const AccordionDebugger = ({ children, componentName, value }: AccordionDebuggerProps) => {
  const { isSpecificComponent } = usePlasmicCanvas();

  if (componentName && isSpecificComponent(componentName)) {
    return (
      <RadixAccordion.Root type="single" value={value}>
        {children}
      </RadixAccordion.Root>
    );
  }

  return <>{children}</>;
};

const COMPONENT_META: ComponentMeta<AccordionDebuggerProps> = {
  name: COMPONENT_NAME,
  importPath: '@radix-ui/react-accordion',
  description:
    'Accordion debugger wraps the accordion provider component around the children to allow for creating reusable components.',
  props: {
    children: 'slot',
    componentName: {
      displayName: 'Which component to check for?',
      description: 'The name of the Plasmic component to enable the debugger for.',
      type: 'string',
    },
    value: {
      displayName: 'Value',
      description: 'The value of the accordion.',
      type: 'string',
      defaultValue: 'accordion-item-1',
    },
  },
};

export const registerAccordionDebugger = ({ loader }: Pick<RegisterComponentParams, 'loader'>) => {
  return registerComponent({
    component: AccordionDebugger,
    meta: COMPONENT_META,
    loader,
  });
};

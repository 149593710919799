import { registerComponent, RegisterComponentParams } from '@air/plasmic-core';
import { ComponentMeta } from '@plasmicapp/host';
import * as RadixDialog from '@radix-ui/react-dialog';

const COMPONENT_NAME = 'DialogClose';

export type DialogCloseProps = Pick<RadixDialog.DialogCloseProps, 'children' | 'className'>;

export const DialogClose = ({ children, className }: DialogCloseProps) => {
  return <RadixDialog.Close className={className}>{children}</RadixDialog.Close>;
};

const COMPONENT_META: ComponentMeta<DialogCloseProps> = {
  name: COMPONENT_NAME,
  importPath: '@radix-ui/react-dialog',
  parentComponentName: 'DialogOverlay',
  props: {
    children: {
      type: 'slot',
    },
  },
};

export const registerDialogClose = ({ loader }: Pick<RegisterComponentParams, 'loader'>) => {
  return registerComponent({
    component: DialogClose,
    loader,
    meta: COMPONENT_META,
  });
};

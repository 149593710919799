import { createContext, Dispatch, ReactNode, Reducer, useContext, useReducer } from 'react';

type State = {
  activeSlide: string;
  slides: string[];
};

type Action =
  | {
      type: 'SET_ACTIVE_SLIDE';
      value: string;
    }
  | {
      type: 'ADD_SLIDE';
      value: string;
    };

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_SLIDE':
      return {
        ...state,
        activeSlide: action.value,
      };

    case 'ADD_SLIDE':
      return {
        ...state,
        slides: [...state.slides, action.value],
      };

    default:
      return state;
  }
};

const SliderContext = createContext<{ state: State; dispatch: Dispatch<Action> } | undefined>(undefined);

export const SliderProvider = ({
  children,
  value = {
    activeSlide: 'default',
    slides: [],
  },
}: {
  children: ReactNode;
  value?: State;
}) => {
  const [state, dispatch] = useReducer(reducer, value);

  return <SliderContext.Provider value={{ state, dispatch }}>{children}</SliderContext.Provider>;
};

export const useSlider = () => {
  const context = useContext(SliderContext);

  if (context === undefined) {
    throw new Error('SliderContext called outside of SliderContextProvider');
  }

  const addSlide = (value: string) => {
    context.dispatch({ type: 'ADD_SLIDE', value });
  };

  const setActiveSlide = (value: string) => {
    context.dispatch({ type: 'SET_ACTIVE_SLIDE', value });
  };

  return {
    activeSlide: context.state.activeSlide,
    addSlide,
    slides: context.state.slides,
    state: context.state,
    setActiveSlide,
  };
};

import { ComponentMeta } from '@plasmicapp/host';
import { Children, cloneElement, isValidElement, ReactNode } from 'react';

import { ComponentName } from '../constants/componentName';
import { BillingCycleType, useBillingCycle } from '../hooks/useBillingCycle';

export type BillingCycleTriggerProps = {
  children: ReactNode;
  type: BillingCycleType;
};

export const BillingCycleTrigger = ({ children, type }: BillingCycleTriggerProps) => {
  const [_billingCycle, setBillingCycle] = useBillingCycle();

  return (
    <>
      {Children.map(children, (child) => {
        if (!isValidElement(child)) return;

        return cloneElement(child, {
          // @ts-ignore
          onClick: () => setBillingCycle(type),
        });
      })}
    </>
  );
};

export const BILLING_CYCLE_TRIGGER_META: ComponentMeta<BillingCycleTriggerProps> = {
  importPath: '@air/plasmic',
  name: ComponentName.BillingCycleTrigger,
  parentComponentName: ComponentName.BillingCycle,
  props: {
    children: {
      type: 'slot',
    },
    type: {
      displayName: 'Switch to',
      type: 'choice',
      options: ['annual', 'monthly'],
      defaultValue: 'annual',
    },
  },
};

import { useTrackPlasmicEvent } from '@air/marketing-analytics';
import { registerComponent, RegisterComponentParams } from '@air/plasmic-core';
import { ComponentMeta } from '@plasmicapp/host';
import { Slot } from '@radix-ui/react-slot';
import { noop } from 'lodash';
import { forwardRef, ReactNode } from 'react';

const COMPONENT_NAME = 'ClickEvent';

export type ClickEventProps = {
  children: ReactNode;
  eventName: string;
  onClick?: (event: any) => void;
  properties?: {
    [key: string]: string;
  };
};

export const ClickEvent = forwardRef<HTMLElement, ClickEventProps>(
  ({ eventName, children, onClick = noop, properties, ...restOfProps }, ref) => {
    const { trackPlasmicEvent } = useTrackPlasmicEvent();

    return (
      <Slot
        onClick={(event) => {
          onClick(event);
          trackPlasmicEvent('Plasmic ' + eventName, properties);
        }}
        ref={ref}
        {...restOfProps}
      >
        {children}
      </Slot>
    );
  },
);

ClickEvent.displayName = 'ClickEvent';

const COMPONENT_META: ComponentMeta<ClickEventProps> = {
  name: COMPONENT_NAME,
  importPath: '@air/marketing-analytics',
  isAttachment: true,
  props: {
    children: 'slot',
    eventName: {
      displayName: 'Event name',
      type: 'choice',
      options: ['Clicked item', 'Custom'],
      defaultValue: 'Clicked item',
    },
    customEventName: {
      displayName: 'Custom event name',
      type: 'string',
      defaultValue: 'Clicked item',
      hidden: (props) => props.eventName !== 'Custom',
    },
    properties: {
      type: 'object',
    },
  },
};

export const registerClickEvent = ({ loader }: Pick<RegisterComponentParams, 'loader'>) => {
  return registerComponent({
    component: ClickEvent,
    loader,
    meta: COMPONENT_META,
  });
};

import { registerComponent, RegisterComponentParams } from '@air/plasmic-core';
import { ComponentMeta } from '@plasmicapp/host';
import { ReactNode } from 'react';
import { animated, SpringValues, useSpring, UseSpringProps } from 'react-spring';

const COMPONENT_NAME = 'AnimateOnClick';

export type AnimateOnClickProps = Pick<UseSpringProps, 'config' | 'from'> & {
  children?: ReactNode;
  className?: string;
  to: SpringValues;
};

export const AnimateOnClick = ({ children, config, from, to, ...rest }: AnimateOnClickProps) => {
  const [styles, api] = useSpring(() => ({
    config,
    ...from,
  }));

  const clickHandler = () => {
    api.start(to);
  };

  return (
    <animated.div {...rest} onClick={clickHandler} style={styles}>
      {children}
    </animated.div>
  );
};

const COMPONENT_META: ComponentMeta<AnimateOnClickProps> = {
  name: COMPONENT_NAME,
  importPath: 'react-spring',
  props: {
    children: 'slot',
    config: {
      type: 'object',
      defaultValue: {
        mass: 1,
        tension: 210,
        friction: 20,
      },
    },
    from: {
      type: 'object',
      defaultValue: {
        width: '30vw',
      },
    },
    to: {
      type: 'object',
      defaultValue: {
        width: '60vw',
      },
    },
  },
};

export const registerAnimateOnClick = ({ loader }: Pick<RegisterComponentParams, 'loader'>) => {
  return registerComponent({
    component: AnimateOnClick,
    loader,
    meta: COMPONENT_META,
  });
};

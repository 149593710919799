import { ComponentMeta } from '@plasmicapp/host';
import { AspectRatio, AspectRatioProps } from '@radix-ui/react-aspect-ratio';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { ComponentName } from '../constants/componentName';
import { useAssetPosition } from '../contexts/AssetPositionProvider';

export type AssetPositionProps = Partial<Pick<AspectRatioProps, 'ratio'>> & {
  className: string;
  id: number;
};

export const AssetPosition = ({ className, id, ratio }: AssetPositionProps) => {
  const assetPositionRef = useRef<HTMLDivElement | null>(null);
  const [hasCreated, setHasCreated] = useState(false);
  const { addAssetPos, updateAssetPos } = useAssetPosition();

  useEffect(() => {
    if (assetPositionRef.current && !hasCreated) {
      const assetRect = assetPositionRef.current.getBoundingClientRect();
      addAssetPos({
        id,
        width: assetRect.width,
        x: assetRect.left + window.pageXOffset,
        y: assetRect.top + window.pageYOffset,
      });
      setHasCreated(true);
    }
  }, [addAssetPos, assetPositionRef, hasCreated, id]);

  useEffect(() => {
    const onResize = debounce(() => {
      if (assetPositionRef.current) {
        const assetRect = assetPositionRef.current.getBoundingClientRect();

        updateAssetPos({
          id,
          width: assetRect.width,
          x: assetRect.left,
          y: assetRect.top + window.pageYOffset,
        });
      }
    }, 1000);

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [assetPositionRef, id, updateAssetPos]);

  return (
    <div
      className={className}
      style={{ position: 'absolute', pointerEvents: 'none', userSelect: 'none' }}
      ref={assetPositionRef}
    >
      {ratio && <AspectRatio ratio={ratio} />}
    </div>
  );
};

export const ASSET_POSITION_META: ComponentMeta<AssetPositionProps> = {
  importPath: '@air/plasmic',
  name: ComponentName.AssetPosition,
  props: {
    id: 'number',
    ratio: 'number',
  },
};

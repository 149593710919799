import {
  trackGTMEvent,
  useTrackClickedLeadFormSubmit,
  useTrackClickedLeadFormSubmit1To10,
  useTrackClickedLeadFormSubmit11Plus,
} from '@air/marketing-analytics';
import { ChilipiperLeadsource, ChilipiperScript, useChilipiper } from '@air/plasmic-chilipiper';
import { ComponentMeta } from '@plasmicapp/host';
import classNames from 'classnames';
import { noop } from 'lodash';
import { ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ComponentName } from '../../constants/componentName';
import { PLASMIC_DEFAULT_CLASS_ALL, PLASMIC_DEFAULT_CLASS_FORM } from '../../constants/plasmicDefaultClasses';

export type ChilipiperFormProps = {
  children: ReactNode;
  className?: string;
  leadsource?: ChilipiperLeadsource;
  onSuccess?: () => void;
  router: string;
};

export const ChilipiperForm = ({
  children,
  className,
  leadsource,
  onSuccess = noop,
  router = 'limit-fields-aug-22',
}: ChilipiperFormProps) => {
  const methods = useForm({
    mode: 'onBlur',
  });
  const { trackClickedLeadFormSubmit } = useTrackClickedLeadFormSubmit();
  const { trackClickedLeadFormSubmit1To10 } = useTrackClickedLeadFormSubmit1To10();
  const { trackClickedLeadFormSubmit11Plus } = useTrackClickedLeadFormSubmit11Plus();
  const { createLead } = useChilipiper({ leadsource, router });

  return (
    <>
      <ChilipiperScript />

      <FormProvider {...methods}>
        <form
          className={classNames(
            'air-form',
            'is-chilipiper',
            PLASMIC_DEFAULT_CLASS_ALL,
            PLASMIC_DEFAULT_CLASS_FORM,
            className,
          )}
          onSubmit={methods.handleSubmit((data) => {
            trackClickedLeadFormSubmit(data);

            if (data?.['company_size']) {
              if (data['company_size'] === '1-10') {
                trackClickedLeadFormSubmit1To10(data);
                trackGTMEvent({
                  event: 'Lead Form Submit (1-10)',
                  eventProps: {
                    label: 'lead form submit',
                    category: 'enterprise',
                    ...data,
                  },
                });
              } else {
                trackClickedLeadFormSubmit11Plus(data);

                trackGTMEvent({
                  event: 'Lead Form Submit (11+)',
                  eventProps: {
                    label: 'lead form submit',
                    category: 'enterprise',
                    ...data,
                  },
                });
              }
            }

            trackGTMEvent({
              event: 'Lead Form Submit',
              eventProps: {
                label: 'lead form submit',
                category: 'enterprise',
                ...data,
              },
            });

            createLead({ onSuccess, values: data });
          })}
        >
          {children}
        </form>
      </FormProvider>
    </>
  );
};

export const CHILIPIPER_FORM_META: ComponentMeta<ChilipiperFormProps> = {
  importPath: '@air/plasmic',
  name: ComponentName.ChilipiperForm,
  props: {
    children: {
      type: 'slot',
    },
    leadsource: {
      type: 'string',
    },
    router: {
      type: 'string',
      defaultValue: 'Router - 11-12-2021',
    },
  },
};

import { registerComponent, RegisterComponentParams } from '@air/plasmic-core';
import { ComponentMeta, PlasmicCanvasContext } from '@plasmicapp/host';
import classNames from 'classnames';
import { useContext, useMemo } from 'react';
import ReactPlayer from 'react-player';

const COMPONENT_NAME = 'Video';

export type VideoProps = {
  airUrl?: string;
  airUrlFallback?: string;
  airUrlPoster?: string;
  autoPlay?: boolean;
  className: string;
  controls?: boolean;
  loop?: boolean;
  muted?: boolean;
  ratioHeight?: number;
  ratioWidth?: number;
  type: 'air' | 'wistia' | 'youtube';
  urlPoster?: string;
  urlVideo?: string;
  urlVideoFallback?: string;
  useAspectRatio?: boolean;
  wistiaAutoPlay?: boolean;
  wistiaControlsVisibleOnLoad?: boolean;
  wistiaEndVideoBehavior?: 'loop' | 'pause' | 'stop';
  wistiaMuted?: boolean;
  wistiaPlaybar?: boolean;
  wistiaPlayButton?: boolean;
  wistiaPlaysInline?: boolean;
  wistiaWmode?: 'opaque' | 'transparent';
  wistiaUrl?: string;
  wistiaFitStrategy?: string;
  playOnVisible?: boolean;
};

export const Video = ({
  airUrl,
  airUrlFallback,
  airUrlPoster,
  autoPlay,
  className,
  controls,
  loop,
  muted,
  ratioHeight,
  ratioWidth,
  type,
  urlVideo,
  urlVideoFallback,
  useAspectRatio,
  wistiaAutoPlay,
  wistiaControlsVisibleOnLoad,
  wistiaEndVideoBehavior,
  wistiaMuted,
  wistiaPlaybar,
  wistiaPlayButton,
  wistiaPlaysInline,
  wistiaWmode,
  wistiaUrl,
  wistiaFitStrategy,
  playOnVisible,
}: VideoProps) => {
  const isEditor = useContext(PlasmicCanvasContext);
  const isWistia = type === 'wistia';
  const isAir = type === 'air';

  const videoUrl = useMemo(() => {
    if (isAir && airUrl) {
      if (airUrlFallback) {
        return [airUrl, airUrlFallback];
      }

      return airUrl;
    }

    if (isWistia && wistiaUrl) {
      return wistiaUrl;
    }

    if (urlVideo) {
      if (urlVideoFallback) {
        return urlVideo;
      }

      return urlVideo;
    }

    return 'https://air-72.wistia.com/medias/qy8wh153xn';
  }, [airUrl, airUrlFallback, isAir, isWistia, urlVideo, urlVideoFallback, wistiaUrl]);

  const wistiaOptions = useMemo(
    () => ({
      autoPlay: wistiaAutoPlay,
      controlsVisibleOnLoad: wistiaControlsVisibleOnLoad,
      endVideoBehavior: wistiaEndVideoBehavior,
      muted: wistiaMuted,
      playbar: wistiaPlaybar,
      playButton: wistiaPlayButton,
      playsInline: wistiaPlaysInline,
      wmode: wistiaWmode,
      fitStrategy: wistiaFitStrategy,
    }),
    [
      wistiaAutoPlay,
      wistiaControlsVisibleOnLoad,
      wistiaEndVideoBehavior,
      wistiaMuted,
      wistiaPlaybar,
      wistiaPlayButton,
      wistiaPlaysInline,
      wistiaWmode,
      wistiaFitStrategy,
    ],
  );

  if (!videoUrl) return null;

  return (
    <div
      className={classNames(
        {
          'air-video': true,
          'is-wistia': isWistia,
          'is-air': isAir,
        },
        className,
      )}
    >
      <div
        className="air-video__container relative h-full w-full"
        style={{
          paddingBottom:
            ratioHeight && ratioWidth && !useAspectRatio ? `${(ratioHeight / ratioWidth) * 100}%` : undefined,
          aspectRatio: useAspectRatio && ratioWidth && ratioHeight ? `${ratioWidth} / ${ratioHeight}` : undefined,
        }}
      >
        <ReactPlayer
          className={classNames('air-video__player absolute inset-0 h-full', {
            'air-video__player--no-ratio': !(ratioHeight && ratioWidth),
          })}
          config={{
            wistia: isWistia
              ? {
                  options: wistiaOptions,
                }
              : undefined,
            ...(isAir
              ? {
                  file: {
                    attributes: {
                      poster: airUrlPoster,
                      autoplay: autoPlay,
                    },
                  },
                }
              : {}),
            file: {
              attributes: { playsInline: true },
            },
          }}
          controls={controls}
          height="100%"
          loop={loop}
          playing={(!isEditor && autoPlay) || playOnVisible}
          muted={muted}
          url={videoUrl}
          width="100%"
        />
      </div>
    </div>
  );
};

export const COMPONENT_META: ComponentMeta<VideoProps> = {
  importPath: 'react-player',
  name: COMPONENT_NAME,
  props: {
    autoPlay: {
      type: 'boolean',
      defaultValue: false,
      displayName: 'Autoplay',
    },
    controls: {
      type: 'boolean',
      defaultValue: false,
      displayName: 'Show video controls',
    },
    loop: {
      type: 'boolean',
      defaultValue: false,
      displayName: 'Loop video',
    },
    muted: {
      type: 'boolean',
      defaultValue: false,
      displayName: 'Mute video',
    },
    playOnVisible: {
      type: 'boolean',
      defaultValue: false,
      displayName: 'Play video when visible in viewport',
    },
    ratioHeight: {
      type: 'number',
      defaultValue: 720,
      displayName: 'Height',
    },
    ratioWidth: {
      type: 'number',
      defaultValue: 1280,
      displayName: 'Width',
    },

    useAspectRatio: {
      type: 'boolean',
      defaultValue: false,
      displayName: 'Use CSS aspect ratio property instead of padding-bottom',
    },

    urlPoster: {
      displayName: 'Poster URL',
      type: 'string',
    },
    urlVideo: {
      type: 'string',
      defaultValue: 'https://air-72.wistia.com/medias/qy8wh153xn',
      displayName: 'Video URL',
    },
    urlVideoFallback: {
      type: 'string',
      defaultValue: 'https://air-72.wistia.com/medias/qy8wh153xn',
      displayName: 'Video Fallback URL',
      hidden: (props: VideoProps) => !props.urlVideo,
    },

    /**
     * Video type
     */
    type: {
      type: 'choice',
      options: [
        {
          label: 'Air',
          value: 'air',
        },
        {
          label: 'YouTube',
          value: 'youtube',
        },
        {
          label: 'Wistia',
          value: 'wistia',
        },
      ],
      defaultValue: 'wistia',
      displayName: 'Video host',
    },

    /**
     * Air options
     */
    airUrl: {
      displayName: 'Air: URL',
      type: 'string',
      hidden: (props: VideoProps) => props.type !== 'air',
    },
    airUrlFallback: {
      displayName: 'Air: Fallback URL',
      type: 'string',
      hidden: (props: VideoProps) => props.type !== 'air',
    },
    airUrlPoster: {
      displayName: 'Air: Poster URL',
      type: 'string',
      hidden: (props: VideoProps) => props.type !== 'air',
    },

    /**
     * Wistia options
     */
    wistiaUrl: {
      displayName: 'Wistia: URL',
      type: 'string',
      defaultValue: 'https://air-72.wistia.com/medias/qy8wh153xn',
      hidden: (props: VideoProps) => props.type !== 'wistia',
    },
    wistiaAutoPlay: {
      displayName: 'Wistia: Autoplay',
      description: 'https://wistia.com/support/developers/embed-options#autoplay',
      type: 'boolean',
      defaultValue: false,
      hidden: (props: VideoProps) => props.type !== 'wistia',
    },
    wistiaControlsVisibleOnLoad: {
      displayName: 'Wistia: Controls visible on load',
      description: 'https://wistia.com/support/developers/embed-options#controlsvisibleonload',
      type: 'boolean',
      defaultValue: true,
      hidden: (props: VideoProps) => props.type !== 'wistia',
    },
    wistiaEndVideoBehavior: {
      displayName: 'Wistia: End video behavior',
      description: 'https://wistia.com/support/developers/embed-options#endvideobehavior',
      type: 'choice',
      options: ['default', 'reset', 'loop'],
      defaultValue: 'default',
      hidden: (props: VideoProps) => props.type !== 'wistia',
    },
    wistiaMuted: {
      displayName: 'Wistia: Muted',
      description: 'https://wistia.com/support/developers/embed-options#muted',
      type: 'boolean',
      defaultValue: false,
      hidden: (props: VideoProps) => props.type !== 'wistia',
    },
    wistiaPlaybar: {
      displayName: 'Wistia: Playbar',
      description: 'https://wistia.com/support/developers/embed-options#playbar',
      type: 'boolean',
      defaultValue: true,
      hidden: (props: VideoProps) => props.type !== 'wistia',
    },
    wistiaPlayButton: {
      displayName: 'Wistia: Play button',
      description: 'https://wistia.com/support/developers/embed-options#playbutton',
      type: 'boolean',
      defaultValue: true,
      hidden: (props: VideoProps) => props.type !== 'wistia',
    },
    wistiaPlaysInline: {
      displayName: 'Wistia: Plays inline',
      description: 'https://wistia.com/support/developers/embed-options#playsinline',
      type: 'boolean',
      defaultValue: false,
      hidden: (props: VideoProps) => props.type !== 'wistia',
    },
    wistiaWmode: {
      displayName: 'Wistia: wmode',
      description: 'https://wistia.com/support/developers/embed-options#wmode',
      type: 'choice',
      options: ['default', 'opaque', 'transparent'],
      defaultValue: 'default',
      hidden: (props: VideoProps) => props.type !== 'wistia',
    },
    wistiaFitStrategy: {
      displayName: 'Wistia: Fit strategy',
      description: 'https://wistia.com/support/developers/embed-options#fitstrategy',
      type: 'choice',
      options: ['contain', 'cover', 'fill', 'none'],
      defaultValue: 'none',
      hidden: (props: VideoProps) => props.type !== 'wistia',
    },
  },

  defaultStyles: {
    width: '100%',
    maxWidth: 400,
  },
};

export const registerVideo = ({ loader }: Pick<RegisterComponentParams, 'loader'>) => {
  return registerComponent({
    component: Video,
    loader,
    meta: COMPONENT_META,
  });
};

import { ComponentMeta } from '@plasmicapp/host';
import * as RadixDialog from '@radix-ui/react-dialog';
import { ReactNode } from 'react';

import { ComponentName } from '../constants/componentName';
import { usePlasmicCanvas } from '../hooks/usePlasmicCanvas';

export type DialogTriggerProps = {
  className?: string;
  children?: ReactNode;
};

export const DialogTrigger = ({ children, className }: DialogTriggerProps) => {
  const { isSpecificComponent } = usePlasmicCanvas();

  const element = (
    <RadixDialog.Trigger asChild>
      <div className={className}>{children}</div>
    </RadixDialog.Trigger>
  );

  if (isSpecificComponent(ComponentName.DialogTrigger)) {
    return <RadixDialog.Root>{element}</RadixDialog.Root>;
  }

  return element;
};

export const DIALOG_TRIGGER_META: ComponentMeta<DialogTriggerProps> = {
  importPath: '@air/plasmic',
  parentComponentName: ComponentName.Dialog,
  name: ComponentName.DialogTrigger,
  isAttachment: true,
  props: {
    children: {
      type: 'slot',
    },
  },
};

import { registerComponent, RegisterComponentParams } from '@air/plasmic-core';
import { ComponentMeta } from '@plasmicapp/host';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import { ReactNode } from 'react';

import styles from './Tooltip.module.css';

const COMPONENT_NAME = 'Tooltip';

export type TooltipProps = Pick<RadixTooltip.TooltipTriggerProps, 'children'> &
  Pick<RadixTooltip.TooltipContentProps, 'align' | 'alignOffset' | 'avoidCollisions' | 'side' | 'sideOffset'> & {
    message: string;
    zIndex?: number;
    markupMessage?: ReactNode;
  };

export const Tooltip = ({
  align,
  alignOffset,
  avoidCollisions,
  children,
  message,
  markupMessage,
  side,
  sideOffset,
  zIndex,
}: TooltipProps) => {
  return (
    <RadixTooltip.Root>
      <RadixTooltip.Trigger asChild type={undefined}>
        {children}
      </RadixTooltip.Trigger>
      <RadixTooltip.Portal>
        <RadixTooltip.Content
          align={align}
          alignOffset={alignOffset}
          avoidCollisions={avoidCollisions}
          className={styles.content}
          side={side}
          sideOffset={sideOffset}
          style={{ zIndex }}
        >
          {message || markupMessage}
          <RadixTooltip.Arrow className={styles.arrow} />
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
};

const COMPONENT_META: ComponentMeta<TooltipProps> = {
  name: COMPONENT_NAME,
  importPath: '@radix-ui/react-tooltip',
  isAttachment: true,
  props: {
    children: 'slot',
    message: {
      type: 'string',
      displayName: 'Message',
      description: 'Text to be displayed inside the tooltip, it overrides the markupMessage prop.',
    },
    markupMessage: 'slot',
    avoidCollisions: {
      displayName: 'Avoid collisions',
      description: 'When true, overrides the side andalign preferences to prevent collisions with boundary edges.',
      type: 'boolean',
      defaultValue: true,
    },
    side: {
      displayName: 'Side',
      description:
        'The preferred side of the trigger to render against when open. Will be reversed when collisions occur and avoidCollisions is enabled.',
      type: 'choice',
      options: ['top', 'right', 'bottom', 'left'],
      defaultValue: 'top',
    },
    sideOffset: {
      displayName: 'Side offset',
      description: 'The distance in pixels from the trigger.',
      type: 'number',
      defaultValue: 0,
      hidden: (props) => !props.side,
    },
    align: {
      displayName: 'Align',
      description: 'The preferred alignment against the trigger. May change when collisions occur.',
      type: 'choice',
      options: ['start', 'center', 'end'],
      defaultValue: 'center',
    },
    alignOffset: {
      displayName: 'Align offset',
      description: 'An offset in pixels from the "start" or "end" alignment options.',
      type: 'number',
      defaultValue: 0,
      hidden: (props) => !props.align,
    },
    zIndex: {
      displayName: 'z-index',
      type: 'number',
      description: 'Set to >= 1 if tooltip is hidden behind content',
    },
  },
};

export const registerTooltip = ({ loader }: Pick<RegisterComponentParams, 'loader'>) => {
  return registerComponent({
    component: Tooltip,
    meta: COMPONENT_META,
    loader,
  });
};

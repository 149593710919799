type GetBadgeStylesParams = {
  background?: string;
  color?: string;
};

type GetBadgeStyles = (params?: GetBadgeStylesParams) => string;

const getBadgeStyles: GetBadgeStyles = (params) => {
  return `font-weight: bold; background-color: ${params?.background ?? '#4030FF'}; color: ${
    params?.color ?? '#FFFFFF'
  }; padding: 2px 8px; border-radius: 4px;`;
};

const isLoggingEnabled = typeof window !== 'undefined' && !!window.localStorage && !!window.localStorage.debug;

export type LoggerParams = {
  label: string;
  message: string;
  type?: 'info' | 'warn' | 'error';
};

export const logger = ({ label = 'Air', message, type = 'info' }: LoggerParams) => {
  if (isLoggingEnabled) {
    switch (type) {
      case 'error':
        console.error(`%c${label}`, getBadgeStyles({ background: '#DD3545' }), message);
      case 'warn':
        console.warn(`%c${label}`, getBadgeStyles({ background: '#FFC008', color: '#212529' }), message);
      default:
        console.info(`%c${label}`, getBadgeStyles(), message);
    }
  }
};

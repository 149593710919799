import { ComponentMeta, GlobalContextMeta, registerComponent, registerGlobalContext } from '@plasmicapp/host';
import { ComponentType } from 'react';

export type RegisterParams = {
  component: ComponentType<any>;
} & (
  | {
      component: ComponentType<any>;
      loader: {
        registerComponent: typeof registerComponent;
      };
      meta: ComponentMeta<ComponentType<any>> | GlobalContextMeta<ComponentType<any>>;
    }
  | {
      component: ComponentType<any>;
      isContext: boolean;
      loader: {
        registerGlobalContext: typeof registerGlobalContext;
      };
      meta: GlobalContextMeta<ComponentType<any>>;
    }
);

export const register = ({ component, ...restOfParams }: RegisterParams) => {
  if (restOfParams.loader) {
    if ('isContext' in restOfParams) {
      return restOfParams.loader.registerGlobalContext(component, restOfParams.meta);
    }
    return restOfParams.loader.registerComponent(component, restOfParams.meta);
  }

  if ('isContext' in restOfParams) {
    return registerGlobalContext(component, restOfParams.meta);
  }

  return registerComponent(component, restOfParams.meta);
};

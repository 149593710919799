import { ComponentMeta } from '@plasmicapp/host';
import { useMemo } from 'react';

import { ComponentName } from '../constants/componentName';
import { useSearchParams } from '../contexts/SearchParamsProvider';

export type SearchParamTextProps = {
  defaultValue: string;
  name: string;
  index: number;
};

export const SearchParamText = ({ defaultValue, name, index, ...restOfProps }: SearchParamTextProps) => {
  const { searchParams } = useSearchParams();
  console.log(defaultValue, name, index, searchParams?.[name]);
  const value = useMemo(() => {
    if (searchParams && searchParams[name]) {
      return searchParams[name][index] ?? defaultValue;
    }

    return defaultValue;
  }, [searchParams, name, index, defaultValue]);

  return <div {...restOfProps}>{value}</div>;
};

export const SEARCH_PARAM_TEXT_META: ComponentMeta<SearchParamTextProps> = {
  importPath: '@air/plasmic',
  name: ComponentName.SearchParamText,
  props: {
    defaultValue: {
      displayName: 'Default value',
      type: 'string',
    },
    name: {
      displayName: 'Name',
      description: 'The name of the search param',
      type: 'string',
    },
    index: {
      displayName: 'Value Index',
      description: 'The index of the value to use from the search param. (Starting from 0)',
      defaultValue: 0,
      type: 'number',
    },
  },
};

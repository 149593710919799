import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

export type SearchParams = {
  [key in string]: string[];
};

export const SearchParamsContext = createContext<{ searchParams?: SearchParams } | undefined>(undefined);

export type SearchParamsProviderProps = {
  children: ReactNode;
  initialState?: SearchParams;
};

export const SearchParamsProvider = ({ children, initialState }: SearchParamsProviderProps) => {
  const [searchParams, setSearchParams] = useState<SearchParams | undefined>(initialState);

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const params: SearchParams = {};

    for (const [key, value] of search) {
      params[key] = value.split(',');
    }

    setSearchParams(params);
  }, []);

  const value = useMemo(() => {
    return { searchParams };
  }, [searchParams]);

  return <SearchParamsContext.Provider value={value}>{children}</SearchParamsContext.Provider>;
};

export const useSearchParams = () => {
  const context = useContext(SearchParamsContext);

  if (context === undefined) {
    throw new Error('PreviewModeContext called outside of PreviewModeProvider');
  }

  return context;
};

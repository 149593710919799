/**
 * Grabs all the URL parameters in the url
 * and returns it in a object.
 */
export const getUTMParameters = (url: string) => {
  const values: { [key: string]: string } = {};
  const params = new URLSearchParams(url);

  params.forEach((value, key) => {
    return (values[key] = value);
  });

  return values;
};

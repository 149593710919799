import { isString } from 'lodash';

export const getPathWithoutQueryStringFromAsPath = (asPath: string) => {
  if (asPath.includes('%3F')) {
    const [locationPathname] = asPath.split('%3F');
    return locationPathname;
  } else {
    const [locationPathname] = asPath.split('?');
    return locationPathname;
  }
};

const assetSubRoute = '/c/:clipId';
const boardSubRoute = '/b/:boardId';
const boardAssetRoute = `${boardSubRoute}${assetSubRoute}`;

const Routes = {
  root: '/',
  home: '/home',
  status: 'https://status.air.inc/',
  helpCenter: 'https://help.air.inc',
  appStore: 'https://itunes.apple.com/app/apple-store/id1244558410?pt=118626354&ct=aircamwebsite&mt=8',
  playStore: 'https://play.google.com/store/apps/details?id=com.airapp.android',
  emailHello: 'mailto:hello@air.inc',
  emailPr: 'mailto:pr@air.inc',
  /** This is a hack because we couldn't get Cloudflare to redirect. */
  productHunt: '/2-0',
  createWorkspace: '/workspace/create',
  joinApprovedWorkspace: '/workspace/join',
  joinWorkspace: '/workspace/join/[shortId]',
  joinWorkspaceWithToken: '/workspace/join/[shortId]',
  redirectWorkspace: '/workspace/redirect',
  workspaceMembersLimit: '/workspace/members-limit',
  verify: '/verify/[dummyCode]/[token]',
  /** This route is a redirect within next.config.js */
  jobs: '/jobs',
  releases: 'https://www.notion.so/aircamera/What-s-New-1d5da1c7779a4e71a94971834d5cdf0e',
  import: '/import/',
  imports: {
    googleDrive: '/import/drive',
    homeBox: '/import/box',
    dropbox: '/import/dropbox',
  },
  external: {
    github: 'https://github.com/AirLabsTeam',
    instagram: 'https://instagram.com/air.hq',
    jobs: 'https://www.notion.so/aircamera/Jobs-02b730f640464a3c9e0e3b1560ec0a07',
    twitter: 'https://twitter.com/airHQ',
  },
  marketing: {
    _sandbox: '/super-secret-path',
    adobePremierePro: '/adobe-premiere-pro',
    adobePremiereProDownload: 'https://partners.adobe.com/exchangeprogram/creativecloud/exchange.details.107604.html',
    agencies: '/agencies',
    agenciesPages: '/agencies/[slug]',
    about: '/about',
    blog: '/blog',
    careers: 'https://apply.workable.com/air/',
    culture: '/culture',
    comparisonPages: '/comparison/[slug]',
    contentCleanersDigitalLibraryOrganizers: '/content-cleaners-digital-library-organizers',
    creatorProgram: '/creator-program',
    demo: 'https://calendly.com/john_air/meetjohn',
    departmentPages: '/department/[slug]',
    departmentDesignCreative: '/department/design-creative',
    departmentMarketing: '/department/marketing',
    departmentProjectAgencyManager: '/department/project-agency-manager',
    desktop: '/air-lift',
    diversity: '/culture/diversity',
    enterprise: '/enterprise',
    features: '/features',
    featuresPages: '/features/[slug]',
    industry: '/industry',
    industryPages: '/industry/[slug]',
    industryRetail: '/industry/retail',
    industryAgencies: '/industry/agencies',
    industryMedia: '/industry/media',
    industryAthletics: '/industry/athletics',
    industryEducation: '/industry/education',
    industryNonprofits: '/industry/nonprofits',
    landingPages: '/landing/[slug]',
    legal: '/legal/[slug]',
    logos: '/logos',
    logosPage: '/logos/[slug]',
    migrate: '/switch',
    pricing: '/pricing',
    privacy: '/legal/privacy',
    productTour: '/tour',
    productTourPages: '/tour/[slug]',
    resources: '/resources',
    resourcesPages: '/resources/[slug]',
    sandbox: '/super-secret-path',
    security: '/security',
    semlp1forma: '/landing/sem-lp-form-1',
    semlp1formb: '/landing/sem-lp-form-2',
    semlp1buttona: '/landing/sem-lp-button-1',
    semlp1buttonb: '/landing/sem-lp-button-2',
    semlphome1: '/landing/sem-lp-home-1',
    semlphome2: '/landing/sem-lp-home-2',
    semlphome3: '/landing/sem-lp-home-3',
    semlphome4: '/landing/sem-lp-home-4',
    terms: '/legal/terms',
    worthlessStudios: '/blog/worthless-studios',
  },
  blog: {
    archive: '/blog/archive',
    categories: '/blog/category',
    category: '/blog/category/[slug]',
    home: '/blog',
    post: '/blog/[slug]',
    /** Blog posts that contains a custom page renderer */
    firstagram: '/blog/1stagram',
    nycRooftopBarsRestaurants: '/blog/nyc-rooftop-bars-restaurants',
    nycCreativeCouncil: '/blog/nyc-creative-council',
    sixFeetCalculator: '/blog/6feetcalculator',
    supportNYC: '/blog/supportnycdtc',
  },
  auth: {
    login: '/login',
    logout: '/logout',
    forgot: '/forgot',
    samlSSOLogin: '/saml-sso-login',
    /** Requires "email" query string */
    resetPassword: '/reset',
    oauth: '/oauth',
    signUp: '/sign-up',
  },
  onboarding: {
    initial: '/onboarding/workspace-name',
    workspaceInfo: '/onboarding/workspace-info',
    approvedDomains: '/onboarding/join-approved-workspaces',
  },
  account: {
    myAccount: '/account/my-account',
    changePassword: '/account/change-password',
    notificationSettings: '/account/notifications',
  },
  media: {
    favoriteItems: '/favorite-items',
    media: '/library',
    myUploads: '/my-uploads',
    recentlyAdded: '/recently-added',
    recentlyModified: '/recently-modified',
    unusedItems: '/unused-items',
    asset: assetSubRoute,
    createLibraryAssetRoute: (id: string) => `/library/c/${id}`,
  },
  board: {
    withAsset: boardAssetRoute,
    root: boardSubRoute,
  },
  workspaceAdmin: {
    checkout: '/workspace/subscription/checkout',
    upgradeByMail: '/workspace/subscription/plan/e/:workspaceId',
    customFields: '/workspace/custom-fields',
    tags: '/workspace/tags',
    edit: '/workspace/edit',
    subscription: '/workspace/subscription',
    security: '/workspace/security',
  },
  workspaceSettings: {
    root: '/workspace',
    members: '/workspace/members',
    sysadmin: '/workspace/sysadmin',
  },
  desktopApp: {
    watchfolders: '/watchfolders',
  },
};

type valueType = string | ((...params: any) => string);

export const getRoutesFromObject = (obj: { [key: string]: valueType }): string[] =>
  Object.keys(obj).reduce((acc: string[], curr) => {
    const current = obj[curr];
    if (isString(current)) {
      return acc.concat(current);
    }

    return acc;
  }, []);

export const marketingRoutes = [Routes.root, ...getRoutesFromObject(Routes.marketing)];
export const blogRoutes = getRoutesFromObject(Routes.blog);

export default Routes;

import { ComponentMeta } from '@plasmicapp/host';
import * as RadixCollapsible from '@radix-ui/react-collapsible';
import { ReactNode, useEffect, useState } from 'react';

import { ComponentName } from '../constants/componentName';
import { usePlasmicCanvas } from '../hooks/usePlasmicCanvas';

export type CollapsibleProps = {
  children: ReactNode;
  open?: boolean;
};

export const Collapsible = ({ children, open = false }: CollapsibleProps) => {
  const [isOpened, setIsOpened] = useState(open);

  const { isEditor } = usePlasmicCanvas();

  useEffect(() => {
    if (isEditor) {
      setIsOpened(open);
    }
  }, [open, isEditor]);

  if (isEditor) {
    return (
      <RadixCollapsible.Root onOpenChange={setIsOpened} open={isOpened}>
        {children}
      </RadixCollapsible.Root>
    );
  }

  return <RadixCollapsible.Root>{children}</RadixCollapsible.Root>;
};

export const COLLAPSIBLE_META: ComponentMeta<CollapsibleProps> = {
  importPath: '@air/plasmic',
  name: ComponentName.Collapsible,
  props: {
    children: {
      type: 'slot',
    },
    open: {
      displayName: 'Open',
      type: 'boolean',
    },
  },
};

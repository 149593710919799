import { registerComponent, RegisterComponentParams } from '@air/plasmic-core';
import { ComponentMeta } from '@plasmicapp/host';
import { Children, ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';
import { animated, useTrail, UseTrailProps } from 'react-spring';

const COMPONENT_NAME = 'Trail';

export type TrailProps = Pick<UseTrailProps, 'config' | 'from' | 'loop' | 'reverse' | 'to'> & {
  animateOnVisiblity?: boolean;
  children?: ReactNode;
  className?: string;
};

export const Trail = ({ animateOnVisiblity, children, className, config, from, loop, reverse, to }: TrailProps) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const items = Children.toArray(children);
  const trail = useTrail(items.length, {
    config,
    from,
    loop,
    reverse,
    to: inView || !animateOnVisiblity ? to : from,
  });

  return (
    <div className={className} ref={ref}>
      {trail.map((styles, index) => (
        <animated.div key={index} style={styles}>
          {items[index]}
        </animated.div>
      ))}
    </div>
  );
};

const COMPONENT_META: ComponentMeta<TrailProps> = {
  name: COMPONENT_NAME,
  importPath: 'react-spring',
  props: {
    children: 'slot',
    config: {
      type: 'object',
      defaultValue: {
        mass: 5,
        tension: 2000,
        friction: 200,
      },
    },
    from: {
      type: 'object',
      defaultValue: {
        opacity: 0,
      },
    },
    to: {
      type: 'object',
      defaultValue: {
        opacity: 1,
      },
    },
    loop: 'boolean',
    reverse: 'boolean',
    animateOnVisiblity: {
      displayName: 'Animate based on visibility',
      type: 'boolean',
      description: 'Animate the component only when the element is in view.',
      defaultValue: false,
    },
  },
};

export const registerTrail = ({ loader }: Pick<RegisterComponentParams, 'loader'>) => {
  return registerComponent({
    component: Trail,
    loader,
    meta: COMPONENT_META,
  });
};

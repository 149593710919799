import { ComponentMeta, PlasmicCanvasContext } from '@plasmicapp/host';
import { ReactNode, useContext } from 'react';

import { ComponentName } from '../constants/componentName';
import { BillingCycleProvider, BillingCycleType } from '../hooks/useBillingCycle';

export type BillingCycleProps = {
  children: ReactNode;
  initialValue: BillingCycleType;
};

export const BillingCycle = ({ children, initialValue }: BillingCycleProps) => {
  const isEditor = useContext(PlasmicCanvasContext);

  /**
   * The initial value only sets the default state when mounted.
   * Changing the state inside Plasmic editor doesn't rerender
   * the provider with the new `initialValue`. Therefore, we
   * apply a key to each of the providers and have it remount
   * when the `initialValue` is changed in the editor.
   */
  if (isEditor) {
    if (initialValue === 'monthly') {
      return (
        <BillingCycleProvider initialValue="monthly" key="monthly">
          {children}
        </BillingCycleProvider>
      );
    } else {
      return (
        <BillingCycleProvider initialValue="annual" key="annual">
          {children}
        </BillingCycleProvider>
      );
    }
  }

  return <BillingCycleProvider initialValue={initialValue}>{children}</BillingCycleProvider>;
};

export const BILLING_CYCLE_META: ComponentMeta<BillingCycleProps> = {
  importPath: '@air/plasmic',
  name: ComponentName.BillingCycle,
  props: {
    children: {
      type: 'slot',
    },
    initialValue: {
      displayName: 'Default Value',
      type: 'choice',
      options: ['annual', 'monthly'],
      defaultValue: 'annual',
    },
  },
};

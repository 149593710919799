import { theme as ZephyrTheme } from '@air/zephyr';
import { type CSSProperties } from 'react';
import type {
  BackgroundProps,
  BorderProps,
  ColorProps,
  DisplayProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  ResponsiveValue,
  ShadowProps,
  SpaceProps,
  TypographyProps,
} from 'styled-system';

import breakpoints from './breakpoints';
import buttons from './buttons';
import buttonSizes from './buttonSizes';
import fontSizes from './fontSizes';
import letterSpacing from './letterSpacing';
import widths from './widths';

const { colors, fonts, fontWeights, radii, space, variants } = ZephyrTheme;

/** @see https://styled-system.com/theme-specification#key-reference */
export const theme = {
  breakpoints,
  colors: {
    ...colors,
    pigeon150: '#CECECE',
    pigeon650: '#3F3E3E',
    pigeon850: '#1A1A1A',
    /**
     * @todo See if we actually need this with the Box component
     */
    inherit: 'inherit',
    currentColor: 'currentColor',
  },
  fonts,
  fontSizes,
  fontWeights,
  letterSpacing,
  radii,
  space,
  /**
   * @deprecated
   */
  widths,

  // custom
  buttons,
  buttonSizes,
  variants,
};

export const marketingTheme = {
  breakpoints: ['768px', '1024px', '1200px', '1440px'],
  fonts: {
    copy: `Inter, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`,
    display: `Gelica, Georgia, serif`,
    monospace: `Menlo, Consolas, Roboto Mono, "Ubuntu Monospace", "Noto Mono", "Oxygen Mono", "Liberation Mono", monospace`,
  },
  zIndices: {
    header: 100,
  },
  variants: {
    ...variants,
    badge: {
      primary: {
        backgroundColor: 'peacock400',
        color: 'white',
      },
      ghost: {
        background: 'transparent',
        border: '1px solid',
        borderColor: 'pigeon100',
        color: 'pigeon500',
      },
    },
    // marketingTheme text headings differ from zephyr with fontWeight at semibold
    'text-heading-72': {
      fontFamily: 'display',
      fontFeatureSettings: `'salt' on, 'ss01' on`,
      fontSize: 72,
      fontWeight: 'semibold',
      letterSpacing: '0.03em',
      lineHeight: 1.1,
    },
    'text-heading-64': {
      fontFamily: 'display',
      fontFeatureSettings: `'salt' on, 'ss01' on`,
      fontSize: 64,
      fontWeight: 'semibold',
      letterSpacing: '0.02em',
      lineHeight: 1.1,
    },
    'text-heading-56': {
      fontFamily: 'display',
      fontFeatureSettings: `'salt' on, 'ss01' on`,
      fontSize: 56,
      fontWeight: 'semibold',
      letterSpacing: '0.03em',
      lineHeight: 1.16,
    },
    'text-heading-48': {
      fontFamily: 'display',
      fontFeatureSettings: `'salt' on, 'ss01' on`,
      fontSize: 48,
      fontWeight: 'semibold',
      letterSpacing: '0.03em',
      lineHeight: 1.16,
    },
    'text-heading-40': {
      fontFamily: 'display',
      fontFeatureSettings: `'salt' on, 'ss01' on`,
      fontSize: 40,
      fontWeight: 'semibold',
      letterSpacing: '0.03em',
      lineHeight: 1.15,
    },
    'text-heading-32': {
      fontFamily: 'display',
      fontFeatureSettings: `'salt' on, 'ss01' on`,
      fontSize: 32,
      fontWeight: 'semibold',
      letterSpacing: '0.03em',
      lineHeight: 1.1,
    },
    'text-heading-24': {
      fontFamily: 'display',
      fontFeatureSettings: `'salt' on, 'ss01' on`,
      fontSize: 24,
      fontWeight: 'semibold',
      letterSpacing: '0.03em',
      lineHeight: 1.1,
    },
    'text-heading-20': {
      fontFamily: 'display',
      fontFeatureSettings: `'salt' on, 'ss01' on`,
      fontSize: 20,
      fontWeight: 'semibold',
      letterSpacing: '0.03em',
      lineHeight: 1.1,
    },
    'text-heading-18': {
      fontFamily: 'display',
      fontFeatureSettings: `'salt' on, 'ss01' on`,
      fontSize: 18,
      fontWeight: 'semibold',
      letterSpacing: '0.03em',
      lineHeight: 1.1,
    },
    'text-heading-16': {
      fontFamily: 'display',
      fontFeatureSettings: `'salt' on, 'ss01' on`,
      fontSize: 16,
      fontWeight: 'semibold',
      letterSpacing: '0.03em',
      lineHeight: 1.1,
    },
  },
};

// blogTheme shares marketingTheme breakpoints and button variant
export const blogTheme = {
  ...marketingTheme,
  variants: {
    ...variants,
    button: marketingTheme.variants.button,
    // yup, unforuntely marketing requires a button style that is not included in the design system
    // and there is nothing we can do about it. we've tried but this is a decision from the higher ups
    'button-filled-blue': {
      backgroundColor: '#3658E7',
      border: 'none',
      color: 'white',
      '&:hover:not(.is-loading)': {
        backgroundColor: '#3658E7',
      },
      '&:active:not(.is-loading)': {
        backgroundColor: '#3658E7',
      },
      '&:disabled:not(.is-loading)': {
        backgroundColor: 'pigeon050',
        color: 'pigeon400',
      },
    },
    'button-outline-blue': {
      backgroundColor: 'transparent',
      border: '1px solid',
      borderColor: 'jay400',
      color: 'jay400',
      '&:hover:not(:disabled)': {
        backgroundColor: 'jay100',
        borderColor: 'transparent',
        color: 'jay600',
      },
      '&:active:not(.is-loading)': {
        backgroundColor: 'jay200',
        borderColor: 'transparent',
        color: 'jay700',
      },
      '&:disabled:not(.is-loading)': {
        color: 'pigeon300',
        borderColor: 'pigeon100',
      },
    },
  },
};

export const nycCreativesTheme = {
  ...marketingTheme,
  fonts: {
    ...marketingTheme.fonts,
    display: `Brice, Georgia, serif`,
  },
  variants: {
    ...marketingTheme.variants,
    'text-heading-48': {
      fontFamily: 'display',
      fontSize: 48,
      fontWeight: 'bold',
      letterSpacing: '-0.03em',
      lineHeight: 1.16,
    },
  },
};

export type ThemeObject = typeof theme;

/**
 * Our theme, but some styled-system keys are stripped out since we don't want them defined via the "tx" prop.
 * @see https://styled-system.com/theme-specification
 */
export type ThemeForTXProp = Omit<ThemeObject, 'variants' | 'buttons' | 'buttonSizes'>;

// Used as a catch-all for using styled-system's responsive styling paradigm on CSS properties with no ties to our theme.
type ResponsiveValues = ResponsiveValue<string | number, ThemeForTXProp>;

// styled-system lets you define these in your theme spec, but we don't.
type UnusedStyledSystemThemeKeys = {
  lineHeights: number[];
  borders: string[];
  borderStyles: string[];
  borderWidths: (string | number)[];
  zIndices: number[];
};

// The types for styled-system requires types to be defined for every key in the spec though.
type TypeSafeStyledSystemTheme = ThemeForTXProp & UnusedStyledSystemThemeKeys;

interface StyledSystemProps
  extends BackgroundProps<TypeSafeStyledSystemTheme>,
    BorderProps<TypeSafeStyledSystemTheme>,
    ColorProps<TypeSafeStyledSystemTheme>,
    DisplayProps<TypeSafeStyledSystemTheme>,
    FlexboxProps<TypeSafeStyledSystemTheme>,
    GridProps<TypeSafeStyledSystemTheme>,
    LayoutProps<TypeSafeStyledSystemTheme>,
    PositionProps<TypeSafeStyledSystemTheme>,
    ShadowProps<TypeSafeStyledSystemTheme>,
    SpaceProps<TypeSafeStyledSystemTheme>,
    TypographyProps<TypeSafeStyledSystemTheme> {}

/**
 * The theme prop. You'll be provided with intellisense for theme-relevant values, documentation for every CSS selector,
 * and bare-minimum typesafety. Think of it as a theme-aware CSS and styled-system props, but in an object.
 */
export type TXProp =
  | (StyledSystemProps & Omit<CSSProperties, keyof StyledSystemProps>)
  | {
      /** Typically meant for CSS keys where we have no prescribed theme values or pseudoselectors */
      [whateverTheHellYouWant: string]: TXProp | ResponsiveValues | ((_theme: ThemeForTXProp) => ResponsiveValues);
    };

import { ComponentMeta } from '@plasmicapp/host';
import * as RadixCollapsible from '@radix-ui/react-collapsible';
import { ReactNode } from 'react';

import { ComponentName } from '../constants/componentName';

export type CollapsibleContentProps = {
  children: ReactNode;
  open?: boolean;
};

export const CollapsibleContent = ({ children }: CollapsibleContentProps) => {
  return <RadixCollapsible.Trigger asChild>{children}</RadixCollapsible.Trigger>;
};

export const COLLAPSIBLE_CONTENT_META: ComponentMeta<CollapsibleContentProps> = {
  importPath: '@air/plasmic',
  parentComponentName: ComponentName.Collapsible,
  name: ComponentName.CollapsibleContent,
  isAttachment: true,
  props: {
    children: {
      type: 'slot',
    },
  },
};

export const assets = [
  {
    title: 'Rectangle 257',
    description: '',
    file: {
      url: '//images.ctfassets.net/l2ai7bec55u7/3FA6eAQS077HWWDllW1mqO/c7141b3b9c9ba27d753404c14309a1b6/Rectangle_257__1_.png',
      details: {
        size: 188369,
        image: {
          width: 1003,
          height: 631,
        },
      },
      fileName: 'Rectangle 257 (1).png',
      contentType: 'image/png',
    },
    updatedAt: '2021-10-18T20:39:39.524Z',
    createdAt: '2021-10-07T22:43:47.677Z',
  },
  {
    title: 'filterbg1',
    description: '',
    file: {
      url: '//images.ctfassets.net/l2ai7bec55u7/4SUvrtlXhqSSL0czoDnvA2/ecd99622231a206bdcdc284e3ccea0df/filterbg1__1_.png',
      details: {
        size: 20131,
        image: {
          width: 618,
          height: 602,
        },
      },
      fileName: 'filterbg1 (1).png',
      contentType: 'image/png',
    },
    updatedAt: '2021-10-18T20:40:15.424Z',
    createdAt: '2021-10-07T22:53:49.275Z',
  },
  {
    title: 'mock 18',
    description: '',
    file: {
      url: '//images.ctfassets.net/l2ai7bec55u7/MfOhSqdj01CYWtY2BXvtO/60d64188cfbabfb2ab7cb178610f1b07/fruity_flavors__1_.png',
      details: {
        size: 101090,
        image: {
          width: 1504,
          height: 848,
        },
      },
      fileName: 'fruity_flavors (1).png',
      contentType: 'image/png',
    },
    updatedAt: '2021-11-01T18:35:19.918Z',
    createdAt: '2021-10-07T22:54:09.796Z',
  },
  {
    title: 'board',
    description: '',
    file: {
      url: '//images.ctfassets.net/l2ai7bec55u7/U2Frqq3wCLkNFpM67Nger/0afe5ed962f772017f459c9f31c2a952/board__1_.png',
      details: {
        size: 36426,
        image: {
          width: 427,
          height: 355,
        },
      },
      fileName: 'board (1).png',
      contentType: 'image/png',
    },
    updatedAt: '2021-10-18T20:41:44.244Z',
    createdAt: '2021-10-07T22:43:39.351Z',
  },
  {
    title: 'Rectangle 261',
    description: '',
    file: {
      url: '//images.ctfassets.net/l2ai7bec55u7/E39gCt3o9qeMsNWEG8jpe/f9e30323097e16e07bf2bb6858ea7cb1/Rectangle_261__1_.png',
      details: {
        size: 220874,
        image: {
          width: 944,
          height: 708,
        },
      },
      fileName: 'Rectangle 261 (1).png',
      contentType: 'image/png',
    },
    updatedAt: '2021-10-18T20:42:04.403Z',
    createdAt: '2021-10-07T22:52:13.639Z',
  },
  {
    title: 'Ellipse 22',
    description: '',
    file: {
      url: '//images.ctfassets.net/l2ai7bec55u7/3nqKvTyOrarlfo9athkHnC/6b26c4a0293c5b855afb63db90fa0bdb/Ellipse_22.svg',
      details: {
        size: 185,
        image: {
          width: 191,
          height: 191,
        },
      },
      fileName: 'Ellipse 22.svg',
      contentType: 'image/svg+xml',
    },
    updatedAt: '2021-10-18T21:10:20.404Z',
    createdAt: '2021-10-18T21:10:20.404Z',
  },
  {
    title: 'Group 620 (1)',
    description: '',
    file: {
      url: '//images.ctfassets.net/l2ai7bec55u7/2ql0TvWFnqdbajQizM8J4B/3aa8373f5f87e41ffc89a75de1c32b78/Group_620__1_.png',
      details: {
        size: 23758,
        image: {
          width: 505,
          height: 360,
        },
      },
      fileName: 'Group_620__1_.png',
      contentType: 'image/png',
    },
    updatedAt: '2021-10-29T06:53:21.588Z',
    createdAt: '2021-10-18T21:11:57.975Z',
  },
];

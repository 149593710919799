import { useCallback } from 'react';

import { useAnalyticsUtilities } from './useAnalyticsUtilities';

export interface MarketingSectionParams {
  contentType?: string;
  contentId?: string;
  contentTitle?: string;
  location?: string;
  order?: number;
  pathName?: string;
}

export const useTrackViewedMarketingSection = () => {
  const { track } = useAnalyticsUtilities();

  const trackViewedMarketingSection = useCallback(
    (params?: MarketingSectionParams) => {
      track('Viewed marketing section', params);
    },
    [track],
  );

  return { trackViewedMarketingSection };
};

import { registerGlobalContext, RegisterGlobalContextParams } from '@air/plasmic-core';
import { GlobalContextMeta } from '@plasmicapp/host';
import * as RadixTooltip from '@radix-ui/react-tooltip';

const COMPONENT_NAME = 'TooltipProvider';

export type TooltipProviderProps = RadixTooltip.TooltipProviderProps;

export const TooltipProvider = RadixTooltip.Provider;

const COMPONENT_META: GlobalContextMeta<TooltipProviderProps> = {
  name: COMPONENT_NAME,
  importPath: '@radix-ui/react-tooltip',
  props: {
    delayDuration: {
      displayName: 'Delay duration',
      description: 'The duration from when the mouse enters a tooltip trigger until the tooltip opens.',
      type: 'number',
      defaultValue: 700,
    },
    skipDelayDuration: {
      displayName: 'Skip delay duration',
      description: 'How much time a user has to enter another trigger without incurring a delay again.',
      type: 'number',
      defaultValue: 700,
    },
    disableHoverableContent: {
      displayName: 'Disable hoverable content',
      description:
        'Prevents Tooltip.Content from remaining open when hovering. Disabling this has accessibility consequences.',
      type: 'boolean',
      defaultValue: false,
    },
  },
};

export const registerTooltipProvider = ({ loader }: Pick<RegisterGlobalContextParams, 'loader'>) => {
  return registerGlobalContext({
    component: TooltipProvider,
    meta: COMPONENT_META,
    loader,
  });
};

import { useCallback } from 'react';

import { sendIntercomLauncherVisibilityEvent } from '../utils/intercomVisibilityListener';
import { useAnalyticsUtilities } from './useAnalyticsUtilities';

/**
 * this shows the intercom widget/button through Segment
 */
export const useTrackShowIntercomWidget = () => {
  const { page } = useAnalyticsUtilities();

  // page events can be used to communicate options to Intercom, see:
  // https://segment.com/docs/connections/destinations/catalog/intercom/#conditionally-show-the-intercom-chat-widget-browser-only
  const trackShowIntercomWidget = useCallback(() => {
    sendIntercomLauncherVisibilityEvent(true);

    page(
      undefined,
      'intercom_show',
      {},
      { Intercom: { hideDefaultLauncher: false }, integrations: { All: false, Intercom: true } },
    );
  }, [page]);

  return { trackShowIntercomWidget };
};

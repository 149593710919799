import { ComponentMeta } from '@plasmicapp/host';
import { ReactNode, useEffect } from 'react';

import { useSlider } from './contexts/SliderContext';
import { SLIDER_NAME } from './Slider';

export const SLIDE_NAME = 'Slide';

export type SlideProps = {
  children?: ReactNode;
  className?: string;
  value: string;
};

export const Slide = ({ children, className, value }: SlideProps) => {
  const { activeSlide, addSlide, slides } = useSlider();

  useEffect(() => {
    if (value && !slides.includes(value)) {
      addSlide(value);
    }
  }, [addSlide, slides, value]);

  if (activeSlide !== value) return null;

  return <div className={className}>{children}</div>;
};

export const SLIDE_META: ComponentMeta<SlideProps> = {
  name: SLIDE_NAME,
  importPath: '@air/plasmic',
  parentComponentName: SLIDER_NAME,
  props: {
    children: 'slot',
    value: {
      displayName: 'Value',
      type: 'string',
    },
  },
};

import { AnalyticsBrowser } from '@segment/analytics-next';
import { createContext, MutableRefObject } from 'react';

export interface AnalyticsContextProps {
  analyticsRef: MutableRefObject<AnalyticsBrowser | undefined>;
  globalPropsRef: MutableRefObject<{}>;
}

const defaultAnalyticsContext: AnalyticsContextProps = {
  analyticsRef: {
    current: undefined,
  },
  globalPropsRef: {
    current: {},
  },
};

export const AnalyticsContext = createContext<AnalyticsContextProps>(defaultAnalyticsContext);

import { registerComponent, RegisterComponentParams } from '@air/plasmic-core';
import { ComponentMeta, PlasmicCanvasContext } from '@plasmicapp/host';
import hexRgb from 'hex-rgb';
import { ComponentProps, useContext } from 'react';
import ReactMarquee from 'react-fast-marquee';

const COMPONENT_NAME = 'Marquee';

export type MarqueeProps = Omit<ComponentProps<typeof ReactMarquee>, 'gradientColor'> & {
  showAll?: boolean;
  gradientColor?: string;
};

export const Marquee = ({
  children,
  className,
  direction,
  gradient,
  gradientColor = '#FFFFFF',
  gradientWidth,
  loop,
  pauseOnHover,
  play,
  showAll,
}: MarqueeProps) => {
  const isEditor = useContext(PlasmicCanvasContext);
  const gradientColorRGB = hexRgb(gradientColor, { format: 'array' }) as unknown as [number, number, number];

  if (isEditor && showAll) {
    return <div className={className}>{children}</div>;
  }

  return (
    <ReactMarquee
      className={className}
      direction={direction}
      gradient={gradient}
      gradientColor={gradientColorRGB}
      gradientWidth={gradientWidth}
      loop={loop}
      pauseOnHover={pauseOnHover}
      play={!isEditor && play}
    >
      {children}
    </ReactMarquee>
  );
};

const COMPONENT_META: ComponentMeta<MarqueeProps> = {
  importPath: 'react-fast-marquee',
  name: COMPONENT_NAME,
  props: {
    children: {
      type: 'slot',
    },
    direction: {
      displayName: 'Direction',
      description: 'The direction the marquee is sliding',
      type: 'choice',
      options: ['left', 'right'],
      defaultValue: 'left',
    },
    gradient: {
      displayName: 'Gradient',
      description: 'Whether to show the gradient(left/right side) or not',
      type: 'boolean',
      defaultValue: true,
    },
    gradientColor: {
      displayName: 'Gradient Color',
      description: 'The rgb color of the gradient as an array of length 3',
      type: 'string',
      defaultValue: '#FFFFFF',
    },
    gradientWidth: {
      displayName: 'Gradient Width',
      description: 'The width of the gradient on either side',
      type: 'number',
      defaultValue: 200,
    },
    loop: {
      displayName: 'Loop',
      description: 'The number of times the marquee should loop, 0 is equivalent to infinite',
      type: 'number',
      defaultValue: 0,
    },
    pauseOnHover: {
      displayName: 'Pause on hover',
      description: 'Whether to pause the marquee when hovered',
      defaultValue: false,
      type: 'boolean',
    },
    play: {
      displayName: 'Playing',
      description: 'Whether to play or pause the marquee',
      defaultValue: true,
      type: 'boolean',
    },
    showAll: {
      displayName: 'Show all',
      description: 'Show all the children of the component at once',
      type: 'boolean',
      defaultValue: false,
    },
  },
};

export const registerMarquee = ({ loader }: Pick<RegisterComponentParams, 'loader'>) => {
  return registerComponent({
    component: Marquee,
    loader,
    meta: COMPONENT_META,
  });
};

import { ComponentMeta } from '@plasmicapp/host';
import * as RadixDialog from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { ReactNode } from 'react';

import { ComponentName } from '../constants/componentName';
import { usePlasmicCanvas } from '../hooks/usePlasmicCanvas';

export type DialogTitleProps = {
  children: ReactNode;
  isVisuallyHidden?: boolean;
};

export const DialogTitle = ({ children, isVisuallyHidden }: DialogTitleProps) => {
  const { isSpecificComponent } = usePlasmicCanvas();

  const element = <RadixDialog.Title asChild>{children}</RadixDialog.Title>;

  if (isSpecificComponent(ComponentName.DialogTitle)) {
    return (
      <RadixDialog.Root>
        <RadixDialog.Content>{element}</RadixDialog.Content>
      </RadixDialog.Root>
    );
  }

  if (isVisuallyHidden) {
    return <VisuallyHidden>{element}</VisuallyHidden>;
  }

  return element;
};

export const DIALOG_TITLE_META: ComponentMeta<DialogTitleProps> = {
  importPath: '@air/plasmic',
  parentComponentName: ComponentName.DialogContent,
  name: ComponentName.DialogTitle,
  isAttachment: true,
  props: {
    children: {
      type: 'slot',
    },
    isVisuallyHidden: {
      displayName: 'Is visually hidden?',
      type: 'boolean',
      defaultValue: false,
    },
  },
};

import { ComponentMeta } from '@plasmicapp/host';
import * as Portal from '@radix-ui/react-portal';
import { Slot } from '@radix-ui/react-slot';
import { AnimatePresence } from 'framer-motion';
import { ReactNode, useEffect } from 'react';
import { useLockBodyScroll } from 'react-use';

import { ComponentName } from '../constants/componentName';
import { MobileNavigationProvider, useMobileNavigation } from '../contexts/MobileNavigationProvider';

export type MobileNavigationProps = {
  className: string;
  menu: ReactNode;
  overlay: ReactNode;
  trigger: ReactNode;
};

export const _MobileNavigation = ({ className, menu, overlay, trigger }: MobileNavigationProps) => {
  const [mobileNavigation, setMobileNavigation] = useMobileNavigation();
  /**
   * Prevents the page from scrolling when the mobile navigation is opened.
   */
  useLockBodyScroll(mobileNavigation);

  /**
   * Closes the mobile navigation when the window is resized.
   */
  useEffect(() => {
    const onClose = () => setMobileNavigation(false);

    window.addEventListener('resize', onClose);

    return () => window.removeEventListener('resize', onClose);
  }, [setMobileNavigation]);

  return (
    <div className={className}>
      <Slot onClick={() => setMobileNavigation(!mobileNavigation)}>{trigger}</Slot>
      <AnimatePresence>
        {mobileNavigation && (
          <Portal.Root>
            {overlay}
            {menu})
          </Portal.Root>
        )}
      </AnimatePresence>
    </div>
  );
};

export const MobileNavigation = (props: MobileNavigationProps) => {
  return (
    <MobileNavigationProvider initialValue={false}>
      <_MobileNavigation {...props} />
    </MobileNavigationProvider>
  );
};

export const MOBILE_NAVIGATION_META: ComponentMeta<MobileNavigationProps> = {
  importPath: '@air/plasmic',
  name: ComponentName.MobileNavigation,
  props: {
    menu: {
      type: 'slot',
    },
    overlay: {
      type: 'slot',
    },
    trigger: {
      type: 'slot',
    },
  },
};

import { ComponentMeta } from '@plasmicapp/host';
import { AspectRatio } from '@radix-ui/react-aspect-ratio';
import { motion, Variants } from 'framer-motion';
import { debounce } from 'lodash';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { ComponentName } from '../../constants/componentName';
import { useAssetPosition } from '../../contexts/AssetPositionProvider';
import { calculateAspectRatioFit } from '../../utils/calculateAspectRatioFit';
import { clamp } from '../../utils/clamp';
import { assets } from './assets';

const ASSETS_CONFIG_MAP = [
  {
    assetPosition: { referenceIndex: 0 },
    animation: { delay: 0.2 },
    imageAnimation: { delay: 0, y: -8 },
    css: { zIndex: 1, width: 520, left: -71, top: 176 },
  },
  {
    animation: { delay: 0.1 },
    imageAnimation: { delay: 0.1, y: -20 },
    css: { zIndex: 3, width: 309, left: 100, top: 110 },
  },
  {
    animation: { delay: 0 },
    imageAnimation: { delay: 0, y: -12 },
    css: { zIndex: 2, width: 751, left: 353, top: 35 },
  },
  {
    assetPosition: { referenceIndex: 1 },
    animation: { delay: 0.2 },
    imageAnimation: { delay: 0, y: -16 },
    css: { zIndex: 4, width: 213, left: 1082, top: 344 },
  },
  {
    assetPosition: { referenceIndex: 2 },
    animation: { delay: 0.3 },
    imageAnimation: { delay: 0.2, y: -8 },
    css: { zIndex: 3, width: 472, left: 1248, top: 101 },
  },
  {
    animation: { delay: 0.2 },
    imageAnimation: { delay: 0.3, y: -12 },
    css: { zIndex: 1, width: 191, top: 0, left: 210 },
  },
  {
    animation: { delay: 0 },
    imageAnimation: { delay: 0.1, y: -16 },
    css: { zIndex: 4, width: 252, top: 110, left: 1042 },
  },
];

const ASSETS_ANIMATION_VARIANTS: Variants = {
  hidden: ({ width, x, y }) => ({
    opacity: 0,
    width,
    x,
    y,
  }),
  visible: {
    opacity: 1,
    width: '100%',
    x: 0,
    y: 0,
  },
};

export type HomeAssetsProps = {
  isInView: boolean;
};

export const HomeAssets = ({}: HomeAssetsProps) => {
  const [canAnimate, setCanAnimate] = useState(false);
  const { ref, inView: isInView } = useInView({ threshold: 0.5 });

  const assetsRef = useRef<HTMLDivElement[]>([]);
  const [assetsCurrentPos, setAssetsCurrentPos] = useState<{ x: number; y: number }[]>([]);
  const { assetsPos } = useAssetPosition();

  useEffect(() => {
    if (assetsRef.current.length > 0 && isInView && assetsCurrentPos.length === 0) {
      assetsRef.current.forEach((assetRef) => {
        setAssetsCurrentPos((previousState) => [
          ...previousState,
          {
            x: assetRef.getBoundingClientRect().left + window.pageXOffset ?? 0,
            y: assetRef.getBoundingClientRect().top + window.pageYOffset ?? 0,
          },
        ]);
      });

      setCanAnimate(true);
    }
  }, [assetsCurrentPos, assetsRef, setAssetsCurrentPos, isInView]);

  useEffect(() => {
    const onResize = debounce(() => {
      assetsRef.current.map((assetRef, index) => {
        setAssetsCurrentPos((previousState) => {
          return previousState.map((prevState, prevStateIndex) =>
            index === prevStateIndex
              ? {
                  x: assetRef.getBoundingClientRect().left + window.pageXOffset ?? 0,
                  y: assetRef.getBoundingClientRect().top + window.pageYOffset ?? 0,
                }
              : prevState,
          );
        });
      });
    }, 1000);

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [assetsRef, setAssetsCurrentPos]);

  return (
    <div style={{ height: clamp(280, 560), marginBottom: clamp(-60, -120) }} ref={ref}>
      <div
        style={{
          position: 'relative',
          width: '100%',
          maxWidth: 1440,
          height: '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {assets?.map((asset, index) => {
          const assetConfig = ASSETS_CONFIG_MAP[index];

          if (!assetConfig) return null;

          const optimizedImageRatios = calculateAspectRatioFit(
            { height: asset.file.details.image.height, width: asset.file.details.image.width },
            { maxWidth: assetConfig.css.width },
          );

          const shouldUseAssetPosition = !!assetConfig.assetPosition;

          const assetPos =
            !!assetConfig.assetPosition && !!assetsPos
              ? assetsPos[assetConfig.assetPosition.referenceIndex]
              : {
                  width: 0,
                  x: 0,
                  y: 0,
                };

          return (
            <div
              key={index}
              ref={(element) => {
                if (element) assetsRef.current[index] = element;
              }}
              style={{
                zIndex: assetConfig.css.zIndex,
                position: 'absolute',
                width: '100%',
                maxWidth: clamp(assetConfig.css.width / 2, assetConfig.css.width),
                top: clamp(assetConfig.css.top / 2, assetConfig.css.top),
                left: clamp(assetConfig.css.left / 2, assetConfig.css.left),
              }}
            >
              <AspectRatio ratio={asset.file.details.image.width / asset.file.details.image.height}>
                <motion.div
                  animate={isInView || !canAnimate ? 'visible' : 'hidden'}
                  custom={{
                    width: shouldUseAssetPosition && canAnimate ? assetPos.width : '100%',
                    x: shouldUseAssetPosition && canAnimate ? assetPos.x - assetsCurrentPos?.[index]?.x : 0,
                    y: shouldUseAssetPosition && canAnimate ? assetPos.y - assetsCurrentPos?.[index]?.y : -100,
                  }}
                  transition={{ type: 'tween', duration: 0.6, delay: assetConfig.animation.delay }}
                  variants={ASSETS_ANIMATION_VARIANTS}
                >
                  <Image
                    alt={asset.description}
                    height={optimizedImageRatios.height}
                    loader={({ quality, src, width }) => {
                      return `https:${src}?w=${width}&q=${quality || 75}`;
                    }}
                    src={asset.file.url}
                    width={optimizedImageRatios.width}
                  />
                </motion.div>
              </AspectRatio>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const HOME_ASSETS_META: ComponentMeta<HomeAssetsProps> = {
  importPath: '@air/plasmic',
  name: ComponentName.HomeAssets,
  props: { isInView: 'boolean' },
};

import { useCallback } from 'react';

import { useAnalyticsUtilities } from './useAnalyticsUtilities';

export const useTrackClickedLeadFormSubmit1To10 = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedLeadFormSubmit1To10 = useCallback(
    (params: any) => {
      track('Lead Form Submit (1-10)', params);
    },
    [track],
  );

  return { trackClickedLeadFormSubmit1To10 };
};

import { useCallback } from 'react';

import { sendIntercomLauncherVisibilityEvent } from '../utils/intercomVisibilityListener';
import { useAnalyticsUtilities } from './useAnalyticsUtilities';

/**
 * this hides the intercom widget/button through Segment
 */
export const useTrackHideIntercomWidget = () => {
  const { page } = useAnalyticsUtilities();

  const trackHideIntercomWidget = useCallback(() => {
    sendIntercomLauncherVisibilityEvent(false);

    // page events can be used to communicate options to Intercom, see:
    // https://segment.com/docs/connections/destinations/catalog/intercom/#conditionally-show-the-intercom-chat-widget-browser-only
    page(
      undefined,
      'intercom_hide',
      {},
      {
        Intercom: { hideDefaultLauncher: true },
        integrations: { All: false, Intercom: true },
      },
    );
  }, [page]);

  return { trackHideIntercomWidget };
};

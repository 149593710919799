import type { CodeComponentMeta } from '@plasmicapp/host';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { uniqueId } from 'lodash';
import { type ReactNode, ComponentProps, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

export const FLYER_COMPONENT_NAME = 'Flyer';

/**
 * This will select a random point on the one of the edge of the screen as the start position
 * and the opposite edge as the end position
 */
const generatePosition = ({
  height,
  imageHeight,
  imageWidth,
  width,
}: {
  height: number;
  imageHeight: number;
  imageWidth: number;
  width: number;
}) => {
  const randomEdge = Math.floor(Math.random() * 4);
  const randomPosition = Math.floor(Math.random() * width);
  const randomWidth = Math.floor(Math.random() * (imageWidth * 0.5)) + imageWidth * 0.5;

  switch (randomEdge) {
    case 0:
      return {
        startX: randomPosition - imageWidth,
        startY: -imageHeight,
        endX: width,
        endY: height,
        edge: 'top',
        width: randomWidth,
      };
    case 1:
      return {
        startX: width,
        startY: randomPosition - imageHeight,
        endX: -imageWidth,
        endY: height,
        edge: 'right',
        width: randomWidth,
      };
    case 2:
      return {
        startX: randomPosition - imageWidth,
        startY: height,
        endX: width,
        endY: -imageHeight,
        edge: 'bottom',
        width: randomWidth,
      };
    case 3:
      return {
        startX: -imageWidth,
        startY: randomPosition - imageHeight,
        endX: width,
        endY: height,
        edge: 'left',
        width: randomWidth,
      };
  }
};

type AssetsProps = {
  height: number;
  image: ReactNode;
  imageHeight: number;
  imageWidth: number;
  numberOfAssets: number;
  width: number;
};

const Assets = ({ height, image, imageHeight, imageWidth, numberOfAssets = 2, width }: AssetsProps) => {
  const [assets, setAssets] = useState(
    Array.from({ length: numberOfAssets }).map(() => ({
      id: uniqueId('asset-'),
      ...generatePosition({ height, imageHeight, imageWidth, width }),
    })),
  );

  const createAsset = (id: string) => {
    const fitleredAssets = assets.filter((asset) => asset.id !== id);
    setAssets([
      ...fitleredAssets,
      {
        id: uniqueId('asset-'),
        ...generatePosition({ height, imageHeight, imageWidth, width }),
      },
    ]);
  };

  return (
    <>
      {assets.map((asset, index) => (
        <motion.div
          className="absolute"
          data-id={asset.id}
          data-edge={asset.edge}
          data-index={index}
          initial={{
            rotate: 0,
            x: asset.startX,
            y: asset.startY,
          }}
          animate={{
            rotate: index % 2 ? 360 : -360,
            x: asset.endX,
            y: asset.endY,
          }}
          key={asset.id}
          style={{
            height: imageHeight,
            width: asset.width,
          }}
          transition={{
            ease: 'linear',
            duration: 10,
          }}
          onAnimationComplete={() => {
            createAsset(asset.id);
          }}
        >
          {image}
        </motion.div>
      ))}
    </>
  );
};

export type FlyerProps = Omit<ComponentProps<'div'>, 'children'> & {
  image: ReactNode;
  imageHeight: number;
  imageWidth: number;
  numberOfAssets: number;
};

export const Flyer = ({
  className,
  image,
  imageHeight,
  imageWidth,
  numberOfAssets = 2,
  ...restOfProps
}: FlyerProps) => {
  const { width, height, ref } = useResizeDetector();

  return (
    <div
      className={classNames('relative w-full h-full overflow-hidden pointer-events-none bg-gray-100', className)}
      ref={ref}
      {...restOfProps}
    >
      {!!width && !!height && (
        <Assets
          image={image}
          imageHeight={imageHeight}
          imageWidth={imageWidth}
          numberOfAssets={numberOfAssets}
          height={height}
          width={width}
        />
      )}
    </div>
  );
};

export const FLYER_META: CodeComponentMeta<FlyerProps> = {
  name: FLYER_COMPONENT_NAME,
  importPath: '@air/plasmic',
  props: {
    image: 'slot',
    imageHeight: {
      type: 'number',
      defaultValue: 601.5037593985,
    },
    imageWidth: {
      type: 'number',
      defaultValue: 400,
    },
    numberOfAssets: {
      type: 'number',
      defaultValue: 2,
    },
  },
};

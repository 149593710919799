import { ComponentMeta } from '@plasmicapp/host';
import * as RadixNavigationMenu from '@radix-ui/react-navigation-menu';
import classNames from 'classnames';

import { ComponentName } from '../constants/componentName';

export type NavigationMenuViewportProps = {
  className: string;
};

export const NavigationMenuViewport = ({ className }: NavigationMenuViewportProps) => {
  return <RadixNavigationMenu.Viewport className={classNames('navigation-menu__viewport', className)} />;
};

export const NAVIGATION_MENU_VIEWPORT_META: ComponentMeta<NavigationMenuViewportProps> = {
  importPath: '@air/plasmic',
  parentComponentName: ComponentName.NavigationMenu,
  name: ComponentName.NavigationMenuViewport,
  props: {
    children: {
      type: 'slot',
    },
  },
};

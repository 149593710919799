import { registerComponent, RegisterComponentParams } from '@air/plasmic-core';
import { ComponentMeta } from '@plasmicapp/host';
import { ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';
import { animated, useSpring, UseSpringProps } from 'react-spring';

const COMPONENT_NAME = 'Animated';

export type AnimatedProps = Pick<UseSpringProps, 'config' | 'from' | 'loop' | 'reverse' | 'to'> & {
  animateOnVisiblity?: boolean;
  children?: ReactNode;
  className?: string;
};

export const Animated = ({
  animateOnVisiblity,
  children,
  className,
  config,
  from,
  loop,
  to,
  reverse,
}: AnimatedProps) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const styles = useSpring({
    config,
    from,
    loop: reverse
      ? {
          reverse,
        }
      : loop,
    to: inView || !animateOnVisiblity ? to : from,
  });

  return (
    <animated.div className={className} style={styles} ref={ref}>
      {children}
    </animated.div>
  );
};

const COMPONENT_META: ComponentMeta<AnimatedProps> = {
  name: COMPONENT_NAME,
  importPath: 'react-spring',
  props: {
    children: 'slot',
    config: {
      type: 'object',
      defaultValue: {
        mass: 1,
        tension: 180,
        friction: 12,
      },
    },
    from: {
      type: 'object',
      defaultValue: {
        opacity: 0,
      },
    },
    to: {
      type: 'object',
      defaultValue: {
        opacity: 1,
      },
    },
    loop: 'boolean',
    reverse: 'boolean',
    animateOnVisiblity: {
      displayName: 'Animate based on visibility',
      type: 'boolean',
      description: 'Animate the component only when the element is in view.',
      defaultValue: false,
    },
  },
};

export const registerAnimated = ({ loader }: Pick<RegisterComponentParams, 'loader'>) => {
  return registerComponent({
    component: Animated,
    loader,
    meta: COMPONENT_META,
  });
};

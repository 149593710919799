import { usePlasmicCanvasContext } from '@plasmicapp/host';
import { includes } from 'lodash';
import { useCallback } from 'react';

export const usePlasmicCanvas = () => {
  const info = usePlasmicCanvasContext();

  const isSpecificComponent = useCallback(
    (componentName: string) => {
      if (typeof info === 'object' && info.componentName) {
        return includes(info.componentName, componentName);
      }

      return false;
    },
    [info],
  );

  return {
    info,
    isEditor: info,
    isSpecificComponent,
  };
};

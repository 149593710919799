import { ComponentMeta } from '@plasmicapp/host';
import * as RadixTabs from '@radix-ui/react-tabs';
import classNames from 'classnames';

import { ComponentName } from '../constants/componentName';
import { PLASMIC_DEFAULT_CLASS_ALL, PLASMIC_DEFAULT_CLASS_DIV } from '../constants/plasmicDefaultClasses';

export type TabsListProp = RadixTabs.TabsListProps;

export const TabsList = ({ children, className, loop }: TabsListProp) => {
  return (
    <RadixTabs.List asChild loop={loop}>
      <div className={classNames('radix-tabs__list', PLASMIC_DEFAULT_CLASS_ALL, PLASMIC_DEFAULT_CLASS_DIV, className)}>
        {children}
      </div>
    </RadixTabs.List>
  );
};

export const TABS_LIST_META: ComponentMeta<TabsListProp> = {
  importPath: '@air/plasmic',
  name: ComponentName.TabsList,
  parentComponentName: ComponentName.Tabs,
  isAttachment: true,
  props: {
    children: {
      type: 'slot',
    },
    loop: {
      displayName: 'Loop',
      description: 'When true, keyboard navigation will loop from last tab to first, and vice versa.',
      type: 'boolean',
      defaultValue: false,
    },
  },
  templates: {
    Basic: {
      props: {
        children: [
          {
            type: 'component',
            name: 'TabsTrigger',
            props: {
              children: [
                {
                  type: 'text',
                  value: 'Tab #1',
                },
              ],
              value: '1',
            },
          },
          {
            type: 'component',
            name: 'TabsTrigger',
            props: {
              children: [
                {
                  type: 'text',
                  value: 'Tab #2',
                },
              ],
              value: '2',
            },
          },
        ],
      },
    },
  },
};

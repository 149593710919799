import { ComponentMeta } from '@plasmicapp/host';
import * as RadixTabs from '@radix-ui/react-tabs';
import classNames from 'classnames';

import { ComponentName } from '../constants/componentName';
import { PLASMIC_DEFAULT_CLASS_ALL, PLASMIC_DEFAULT_CLASS_DIV } from '../constants/plasmicDefaultClasses';
import { usePlasmicCanvas } from '../hooks/usePlasmicCanvas';

export type TabsContentProp = RadixTabs.TabsContentProps;

export const TabsContent = ({ children, className, value }: TabsContentProp) => {
  const { isSpecificComponent } = usePlasmicCanvas();

  const element = (
    <RadixTabs.Content asChild value={value}>
      <div
        className={classNames('radix-tabs__content', PLASMIC_DEFAULT_CLASS_ALL, PLASMIC_DEFAULT_CLASS_DIV, className)}
      >
        {children}
      </div>
    </RadixTabs.Content>
  );

  if (isSpecificComponent(ComponentName.TabsContent)) {
    return <RadixTabs.Root value={value}>{element}</RadixTabs.Root>;
  }

  return element;
};

export const TABS_CONTENT_META: ComponentMeta<TabsContentProp> = {
  importPath: '@air/plasmic',
  name: ComponentName.TabsContent,
  parentComponentName: ComponentName.Tabs,
  isAttachment: true,
  props: {
    children: {
      type: 'slot',
    },
    value: {
      displayName: 'Tab value',
      description: 'A unique value that associates the content with a trigger.',
      type: 'string',
      defaultValue: 'defaultValue',
    },
  },
  defaultStyles: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    borderStyle: 'none',
    borderColor: 'transparent',
  },
};

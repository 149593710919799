import { registerComponent, RegisterComponentParams } from '@air/plasmic-core';
import { ComponentMeta } from '@plasmicapp/host';
import * as RadixAccordion from '@radix-ui/react-accordion';
import { ReactNode, useEffect, useState } from 'react';

import { usePlasmicCanvas } from '../../../hooks';

const COMPONENT_NAME = 'Accordion';

export type AccordionProps = {
  children?: ReactNode;
  collapsible: boolean;
  defaultValue: string;
  type: 'single' | 'multiple';
};

export const Accordion = ({ children, collapsible, type = 'single', defaultValue }: AccordionProps) => {
  const { isEditor } = usePlasmicCanvas();
  const isMultiple = type === 'multiple';
  const isSingle = type === 'single';
  const [items, setItems] = useState(isSingle ? defaultValue : [defaultValue]);

  useEffect(() => {
    if (isEditor) {
      if (isMultiple) {
        setItems([defaultValue]);
      }
      {
        setItems(defaultValue);
      }
    }
  }, [defaultValue, isEditor, isMultiple]);

  if (isMultiple) {
    return (
      <RadixAccordion.Root
        asChild
        defaultValue={[defaultValue]}
        onValueChange={setItems}
        type="multiple"
        value={items as string[]}
      >
        {children}
      </RadixAccordion.Root>
    );
  }

  return (
    <RadixAccordion.Root
      asChild
      collapsible={collapsible}
      defaultValue={defaultValue}
      onValueChange={setItems}
      type="single"
      value={items as string}
    >
      {children}
    </RadixAccordion.Root>
  );
};

const COMPONENT_META: ComponentMeta<AccordionProps> = {
  name: COMPONENT_NAME,
  importPath: '@radix-ui/react-accordion',
  props: {
    children: {
      type: 'slot',
    },
    defaultValue: {
      type: 'string',
      defaultValue: 'accordion-item-1',
    },
    type: {
      displayName: 'Type',
      description: 'Determines whether one or multiple items can be opened at the same time.',
      type: 'choice',
      options: ['single', 'multiple'],
      defaultValue: 'single',
    },
    collapsible: {
      displayName: 'Collapsible',
      description: 'When type is "single", allows closing content when clicking trigger for an open item.',
      type: 'boolean',
      defaultValue: false,
      hidden: (props) => props.type !== 'single',
    },
  },
};

export const registerAccordion = ({ loader }: Pick<RegisterComponentParams, 'loader'>) => {
  return registerComponent({
    component: Accordion,
    loader,
    meta: COMPONENT_META,
  });
};

import { logger } from '@air/marketing-logger';
import { Options } from '@segment/analytics-next';
import { useCallback } from 'react';

import useAnalyticsContext from './useAnalyticsContext';

export interface SegmentGlobalProps {
  email_verified?: boolean;
  current_url?: string;
  OS?: string;
  screen_height?: number;
  screen_width?: number;
  initial_referrer?: string;
  initial_referring_domain?: string;
  referrer?: string;
  referring_domain?: string;
}

type includeDestinations = 'Intercom' | 'Customer.io (Actions)';

type OptionsParams = Options & {
  integrations?: {
    [key in includeDestinations]?: boolean;
  };
};

/**
 * Hook for analytics functions which rely on useAnalyticsContext
 */
export const useAnalyticsUtilities = () => {
  const { analyticsRef, globalPropsRef } = useAnalyticsContext();

  // sets global properties which will then be passed to every subsequent track call
  const setGlobalEventProps = useCallback(
    (params: SegmentGlobalProps) => {
      globalPropsRef.current = { ...globalPropsRef.current, ...params };
    },
    [globalPropsRef],
  );

  // resets global properties
  const resetGlobalEventProps = useCallback(() => {
    globalPropsRef.current = {};
  }, [globalPropsRef]);

  // this is used to track page views.
  // many destinations require this to be called at least once to initialize properly
  const page = useCallback(
    (category?: string, name?: string, params?: {}, options?: OptionsParams) => {
      const { integrations, ...rest } = options || {};

      analyticsRef.current?.page(
        category,
        name,
        {
          ...globalPropsRef.current,
          ...(params || {}),
        },
        {
          integrations: {
            /**
             * Page events can interact with Intercom's messenger. We explicitly avoid
             * sending events there unless otherwise overridden (like in `trackShowIntercomWidget`).
             * An intercom initialization page event is located in `AnalyticsPageRouting`.
             */
            Intercom: true,
            ...integrations,
          },
          ...rest,
        },
      );

      logger({
        label: 'Analytics (Page)',
        message: `${category} ${name}`,
        type: 'info',
      });
    },
    [analyticsRef, globalPropsRef],
  );

  /**
   * This is used for generalized tracking events.
   */
  const track = useCallback(
    (event: string, params?: {}, options?: OptionsParams) => {
      const { integrations, ...rest } = options || {};
      analyticsRef.current?.track(
        event,
        {
          ...globalPropsRef.current,
          ...(params || {}),
        },
        {
          integrations: {
            // Intercom is a "device-mode" source in Segment; this means we must specifiy in code if an event should be routed there.
            // However, there is a limit on the number of events Intercom can accept, so it doesn't send by default
            Intercom: false,
            ...integrations,
          },
          ...rest,
        },
      );
      logger({
        label: 'Analytics (Track)',
        message: event,
        type: 'info',
      });
    },
    [analyticsRef, globalPropsRef],
  );

  return {
    setGlobalEventProps,
    resetGlobalEventProps,
    page,
    track,
  };
};

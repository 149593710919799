import { ComponentMeta } from '@plasmicapp/host';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ComponentName } from '../../constants/componentName';
import { PLASMIC_DEFAULT_CLASS_ALL, PLASMIC_DEFAULT_CLASS_FORM } from '../../constants/plasmicDefaultClasses';

export type FormProps = {
  children: ReactNode;
  className?: string;
};

export const Form = ({ children, className }: FormProps) => {
  const methods = useForm({
    mode: 'onBlur',
  });

  return (
    <FormProvider {...methods}>
      <form
        className={classNames('air-form', PLASMIC_DEFAULT_CLASS_ALL, PLASMIC_DEFAULT_CLASS_FORM, className)}
        onSubmit={methods.handleSubmit((data) => console.log(data))}
      >
        {children}
      </form>
    </FormProvider>
  );
};

export const FORM_META: ComponentMeta<FormProps> = {
  importPath: '@air/plasmic',
  name: ComponentName.Form,
  props: {
    children: {
      type: 'slot',
    },
  },
};

export const INTERCOM_LAUNCHER_HEIGHT = 60 + 20; // height - 60, bottom - 20;

let isLauncherVisible = false;
const visibilityListeners: IntercomLauncherVisibilityListener[] = [];

export const sendIntercomLauncherVisibilityEvent = (isVisible: boolean) => {
  if (isVisible !== isLauncherVisible) {
    isLauncherVisible = isVisible;
    visibilityListeners.forEach((c) => c(isLauncherVisible));
  }
};

type IntercomLauncherVisibilityListener = (isVisible: boolean) => void;

export const subscribeToIntercomLauncherVisibility = (callback: IntercomLauncherVisibilityListener) => {
  visibilityListeners.push(callback);

  return {
    unsubscribe: () => visibilityListeners.filter((c) => c === callback),
  };
};

export const isIntercomLauncherVisible = () => isLauncherVisible;

import { registerComponent, RegisterComponentParams } from '@air/plasmic-core';
import { ComponentMeta } from '@plasmicapp/host';
import * as RadixAccordion from '@radix-ui/react-accordion';

const COMPONENT_NAME = 'AccordionItem';

export type AccordionItemProps = Pick<RadixAccordion.AccordionItemProps, 'className' | 'children' | 'value'>;

export const AccordionItem = ({ children, className, value }: AccordionItemProps) => {
  return (
    <RadixAccordion.Item className={className} value={value}>
      {children}
    </RadixAccordion.Item>
  );
};

const COMPONENT_META: ComponentMeta<AccordionItemProps> = {
  importPath: '@air/plasmic',
  name: COMPONENT_NAME,
  parentComponentName: 'Accordion',
  props: {
    children: {
      type: 'slot',
    },
    disabled: {
      description: 'When true, prevents the user from interacting with the item.',
      type: 'boolean',
      defaultValue: false,
    },
    value: {
      description: 'A unique value for the item.',
      type: 'string',
      defaultValue: 'accordion-item-1',
    },
  },
};

export const registerAccordionItem = ({ loader }: Pick<RegisterComponentParams, 'loader'>) => {
  return registerComponent({
    component: AccordionItem,
    loader,
    meta: COMPONENT_META,
  });
};

import { registerComponent, RegisterComponentParams } from '@air/plasmic-core';
import { ComponentMeta } from '@plasmicapp/host';
import { ReactNode, useState } from 'react';
import { animated, useTransition, UseTransitionProps } from 'react-spring';

const COMPONENT_NAME = 'Transition';

export type TransitionProps = Pick<UseTransitionProps, 'delay' | 'enter' | 'from' | 'leave'> & {
  children?: ReactNode;
  className?: string;
};

export const Transition = ({ children, className, delay, enter, from, leave }: TransitionProps) => {
  const [show, setShow] = useState(false);
  const transitions = useTransition(show, {
    delay,
    enter,
    from,
    leave,
    onRest: () => setShow(!show),
  });

  return transitions(
    (styles, item) =>
      item && (
        <animated.div className={className} style={styles}>
          {children}
        </animated.div>
      ),
  );
};

const COMPONENT_META: ComponentMeta<TransitionProps> = {
  name: COMPONENT_NAME,
  importPath: 'react-spring',
  props: {
    children: 'slot',
    initial: {
      type: 'object',
      defaultValue: {
        opacity: 0,
      },
    },
    from: {
      type: 'object',
      defaultValue: {
        opacity: 0,
      },
    },
    enter: {
      type: 'object',
      defaultValue: {
        opacity: 1,
      },
    },
    leave: {
      type: 'object',
      defaultValue: {
        opacity: 0,
      },
    },
  },
};

export const registerTransition = ({ loader }: Pick<RegisterComponentParams, 'loader'>) => {
  return registerComponent({
    component: Transition,
    loader,
    meta: COMPONENT_META,
  });
};

import { ComponentMeta } from '@plasmicapp/host';
import * as RadixNavigationMenu from '@radix-ui/react-navigation-menu';
import { ReactNode } from 'react';

import { ComponentName } from '../constants/componentName';

export type NavigationMenuIndicatorProps = {
  children: ReactNode;
};

export const NavigationMenuIndicator = ({ children }: NavigationMenuIndicatorProps) => {
  return <RadixNavigationMenu.Indicator asChild>{children}</RadixNavigationMenu.Indicator>;
};

export const NAVIGATION_MENU_INDICATOR_META: ComponentMeta<NavigationMenuIndicatorProps> = {
  importPath: '@air/plasmic',
  parentComponentName: ComponentName.NavigationMenuList,
  name: ComponentName.NavigationMenuIndicator,
  props: {
    children: {
      type: 'slot',
    },
  },
};

import { useTrackClickedActionItem } from '@air/marketing-analytics';
import { ComponentMeta } from '@plasmicapp/host';
import classNames from 'classnames';
import { noop } from 'lodash';
import Link from 'next/link';
import { forwardRef, HTMLProps, ReactNode } from 'react';

import { ComponentName } from '../constants/componentName';
import { PLASMIC_DEFAULT_CLASS_ALL, PLASMIC_DEFAULT_CLASS_BUTTON } from '../constants/plasmicDefaultClasses';

export type ButtonProps = Pick<HTMLProps<HTMLButtonElement>, 'onClick'> & {
  className?: string;
  children: ReactNode;
  disabled?: boolean;
  href?: string;
  type: 'button' | 'submit';
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, disabled, href, onClick = noop, type, ...restOfProps }: ButtonProps, ref) => {
    const { trackClickedActionItem } = useTrackClickedActionItem();

    const sharedClassNames = classNames(
      'air-button',
      PLASMIC_DEFAULT_CLASS_ALL,
      PLASMIC_DEFAULT_CLASS_BUTTON,
      className,
    );

    if (href) {
      if (href.charAt(0) === '#') {
        return (
          <a className={sharedClassNames} href={href} {...restOfProps}>
            {children}
          </a>
        );
      }

      return (
        <Link href={href} passHref>
          <a className={sharedClassNames} {...restOfProps}>
            {children}
          </a>
        </Link>
      );
    }

    return (
      <button
        className={sharedClassNames}
        disabled={disabled}
        onClick={(event) => {
          onClick(event);
          trackClickedActionItem();
        }}
        ref={ref}
        type={type}
        {...restOfProps}
      >
        {children}
      </button>
    );
  },
);

Button.displayName = 'Button';

export const BUTTON_META: ComponentMeta<ButtonProps> = {
  importPath: '@air/plasmic',
  name: ComponentName.Button,
  props: {
    children: {
      type: 'slot',
    },
    disabled: {
      displayName: 'Disabled',
      type: 'boolean',
      defaultValue: false,
    },
    href: {
      displayName: 'URL',
      type: 'string',
    },
    type: {
      displayName: 'Type',
      type: 'choice',
      options: ['button', 'submit'],
      defaultValue: 'button',
    },
  },
};

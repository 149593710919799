import { registerComponent, RegisterComponentParams } from '@air/plasmic-core';
import { ComponentMeta } from '@plasmicapp/host';
import * as RadixAccordion from '@radix-ui/react-accordion';

const COMPONENT_NAME = 'AccordionHeader';

export type AccordionHeaderProps = Pick<RadixAccordion.AccordionHeaderProps, 'children' | 'className'>;

export const AccordionHeader = ({ children, className }: AccordionHeaderProps) => {
  return <RadixAccordion.Header className={className}>{children}</RadixAccordion.Header>;
};

const COMPONENT_META: ComponentMeta<AccordionHeaderProps> = {
  name: COMPONENT_NAME,
  importPath: '@radix-ui/react-accordion',
  parentComponentName: 'AccordionItem',
  props: {
    children: {
      type: 'slot',
    },
  },
};

export const registerAccordionHeader = ({ loader }: Pick<RegisterComponentParams, 'loader'>) => {
  return registerComponent({
    component: AccordionHeader,
    loader,
    meta: COMPONENT_META,
  });
};

import { ComponentMeta, PlasmicCanvasContext } from '@plasmicapp/host';
import classNames from 'classnames';
import { useContext } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';

import { ComponentName } from '../../constants/componentName';
import { PLASMIC_DEFAULT_CLASS_ALL, PLASMIC_DEFAULT_CLASS_INPUT } from '../../constants/plasmicDefaultClasses';
import { SUPPORTED_EMAILS, VALID_EMAIL } from '../../constants/regexPatterns';

export type HookInputProps = {
  className: string;
  emailPattern?: 'business-only' | 'default';
  name: string;
  placeholder?: string;
  strict?: boolean;
  type?: 'date' | 'color' | 'email' | 'password' | 'search' | 'tel' | 'text' | 'time' | 'url';
} & Pick<RegisterOptions, 'disabled' | 'required' | 'value'>;

export const ReactHookFormInput = ({
  className,
  disabled,
  name,
  placeholder,
  required,
  strict,
  type,
  value,
}: HookInputProps) => {
  const { register } = useFormContext();

  return (
    <input
      className={className}
      placeholder={placeholder}
      {...register(name, {
        disabled,
        required: required ? 'This field is required.' : false,
        value,
        pattern:
          type === 'email'
            ? strict
              ? {
                  value: SUPPORTED_EMAILS,
                  message: 'Oops, a personal email domain. Try Air free at Air.inc',
                }
              : {
                  value: VALID_EMAIL,
                  message: 'Please enter a valid email address.',
                }
            : undefined,
      })}
    />
  );
};

export type InputProps = HookInputProps & {
  required?: boolean;
};

export const Input = ({ className, disabled, name, placeholder, required, strict, type, value }: InputProps) => {
  const isEditor = useContext(PlasmicCanvasContext);

  if (!isEditor) {
    return (
      <ReactHookFormInput
        className={classNames('air-input', PLASMIC_DEFAULT_CLASS_ALL, PLASMIC_DEFAULT_CLASS_INPUT, className)}
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        required={required}
        strict={strict}
        type={type}
        value={value}
      />
    );
  }

  return (
    <input
      className={classNames('air-input', PLASMIC_DEFAULT_CLASS_ALL, PLASMIC_DEFAULT_CLASS_INPUT, className)}
      disabled={disabled}
      name={name}
      placeholder={placeholder}
      required={required}
      type={type}
      value={value}
    />
  );
};

export const INPUT_META: ComponentMeta<InputProps> = {
  importPath: '@air/plasmic',
  name: ComponentName.Input,
  props: {
    disabled: {
      displayName: 'Disabled',
      type: 'boolean',
      defaultValue: false,
    },
    emailPattern: {
      displayName: 'Email Pattern',
      description: 'The type of validation that is done on the email type',
      type: 'choice',
      options: ['business-only', 'default'],
      defaultValue: 'default',
    },
    name: {
      displayName: 'Name',
      type: 'string',
      defaultValue: '',
    },
    placeholder: {
      displayName: 'Placeholder',
      type: 'string',
    },
    required: {
      displayName: 'Required',
      type: 'boolean',
      defaultValue: false,
    },
    strict: {
      displayName: 'Strict validation',
      type: 'boolean',
      defaultValue: false,
    },
    type: {
      displayName: 'Type',
      type: 'choice',
      options: ['date', 'color', 'email', 'password', 'search', 'tel', 'text', 'time', 'url'],
      defaultValue: 'text',
    },
    value: {
      displayName: 'Default Value',
      type: 'string',
      defaultValue: '',
    },
  },
};

import { useCallback } from 'react';

import { useAnalyticsUtilities } from './useAnalyticsUtilities';

/**
 * Logs event for when a user viewedHomePage
 */
export const useTrackViewedHomePage = () => {
  const { track } = useAnalyticsUtilities();

  const trackViewedHomePage = useCallback(() => {
    track('Viewed home page');
  }, [track]);

  return { trackViewedHomePage };
};

import { ComponentMeta } from '@plasmicapp/host';
import * as RadixTabs from '@radix-ui/react-tabs';
import classNames from 'classnames';
import { motion, useReducedMotion } from 'framer-motion';
import { ReactNode } from 'react';

import { ComponentName } from '../constants/componentName';
import { TabsProvider, useTabs } from '../contexts/TabsProvider';
import { usePlasmicCanvas } from '../hooks/usePlasmicCanvas';

export type TabsTriggerProp = RadixTabs.TabsTriggerProps & {
  _className: string;
  collapsible?: ReactNode;
  isCollapsible?: boolean;
};

export const _TabsTrigger = ({
  children,
  _className,
  className,
  collapsible,
  isCollapsible,
  value,
}: TabsTriggerProp) => {
  const [tab] = useTabs();
  const shouldReduceMotion = useReducedMotion();
  const isActive = tab === value;

  return (
    <RadixTabs.Trigger asChild value={value} type={undefined}>
      <div className={classNames(className, _className)} data-state={isActive ? 'active' : undefined}>
        {children}
        {isCollapsible && (
          <motion.div
            animate={{
              height: isActive || shouldReduceMotion ? 'auto' : 0,
              opacity: isActive ? 1 : 0,
            }}
            initial={{
              height: shouldReduceMotion ? 'auto' : 0,
              opacity: 0,
            }}
            transition={{ type: 'linear', duration: 0.4 }}
            style={{ overflow: 'hidden' }}
          >
            {collapsible}
          </motion.div>
        )}
      </div>
    </RadixTabs.Trigger>
  );
};

export const TabsTrigger = ({ value, ...restOfProps }: TabsTriggerProp) => {
  const { isSpecificComponent } = usePlasmicCanvas();

  const element = <_TabsTrigger value={value} {...restOfProps} />;

  if (isSpecificComponent(ComponentName.TabsTrigger)) {
    return (
      <TabsProvider initialValue={value}>
        <RadixTabs.Root>
          <RadixTabs.List>{element}</RadixTabs.List>
        </RadixTabs.Root>
      </TabsProvider>
    );
  }
  return element;
};

export const TABS_TRIGGER_META: ComponentMeta<TabsTriggerProp> = {
  importPath: '@air/plasmic',
  name: ComponentName.TabsTrigger,
  parentComponentName: ComponentName.TabsList,
  isAttachment: true,
  props: {
    _className: {
      displayName: 'Class Name',
      type: 'string',
    },
    children: {
      type: 'slot',
    },
    collapsible: {
      type: 'slot',
    },
    disabled: {
      displayName: 'Disabled',
      description: 'When true, prevents the user from interacting with the tab.',
      type: 'boolean',
      defaultValue: false,
    },
    isCollapsible: {
      displayName: 'Is Collapsible?',
      type: 'boolean',
      defaultValue: false,
    },
    value: {
      displayName: 'Tab value',
      description: 'A unique value that associates the trigger with a content.',
      defaultValue: 'defaultValue',
      type: 'string',
    },
  },
  defaultStyles: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    borderStyle: 'none',
    borderColor: 'transparent',
  },
};

import { ComponentMeta } from '@plasmicapp/host';
import { ReactNode } from 'react';

import { ComponentName } from '../../constants/componentName';
import { clamp } from '../../utils/clamp';

export type HomeHeroProps = {
  actions: ReactNode;
  content: ReactNode;
  title: ReactNode;
};

export const HomeHero = ({ actions, content, title }: HomeHeroProps) => {
  return (
    <section
      style={{
        zIndex: 2,
        position: 'relative',
        minHeight: clamp(524, 984),
        paddingTop: 152,
        boxSizing: 'border-box',
        overflow: 'visible',
      }}
    >
      <div
        style={{
          maxWidth: 1224,
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingLeft: 32,
          paddingRight: 32,
        }}
      >
        <div style={{ maxWidth: 748, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
          {title}
          {content}
        </div>
        {actions}
      </div>
    </section>
  );
};

export const HOME_HERO_META: ComponentMeta<HomeHeroProps> = {
  importPath: '@air/plasmic',
  name: ComponentName.HomeHero,
  props: {
    actions: 'slot',
    content: 'slot',
    title: 'slot',
  },
};

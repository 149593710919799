import { ComponentMeta } from '@plasmicapp/host';
import { motion, useReducedMotion } from 'framer-motion';
import { ReactNode, useEffect } from 'react';

import { ComponentName } from '../constants/componentName';
import { MobileNavigationProvider, useMobileNavigation } from '../contexts/MobileNavigationProvider';
import { usePlasmicCanvas } from '../hooks/usePlasmicCanvas';

export const LEFT_TO_RIGHT_ANIMATION = {
  hidden: {
    opacity: 0,
    x: -96,
  },
  visible: {
    display: 'flex',
    x: 0,
    opacity: 1,
  },
};

export const RIGHT_TO_LEFT_ANIMATION = {
  hidden: {
    opacity: 0,
    x: 96,
  },
  visible: {
    display: 'flex',
    x: 0,
    opacity: 1,
  },
};

export const REDUCED_ANIMATION = {
  closed: {
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
  },
  opened: {
    display: 'flex',
    opacity: 1,
  },
};

export type MobileNavigationMenuProps = {
  children: ReactNode;
  className: string;
  isOpen: boolean;
  dir: 'ltr' | 'rtl';
};

export const _MobileNavigationMenu = ({ children, className, dir, isOpen }: MobileNavigationMenuProps) => {
  const { isEditor } = usePlasmicCanvas();

  const shouldReduceMotion = useReducedMotion();
  const [_mobileNavigation, setMobileNavigation] = useMobileNavigation();

  useEffect(() => {
    if (isEditor) {
      setMobileNavigation(isOpen);
    }
  }, [isEditor, isOpen, setMobileNavigation]);

  return (
    <motion.div
      animate="visible"
      className={className}
      exit="hidden"
      initial="hidden"
      key={ComponentName.MobileNavigationMenu}
      transition={{ duration: 0.4, type: 'tween' }}
      variants={
        shouldReduceMotion ? REDUCED_ANIMATION : dir === 'ltr' ? LEFT_TO_RIGHT_ANIMATION : RIGHT_TO_LEFT_ANIMATION
      }
    >
      {children}
    </motion.div>
  );
};

export const MobileNavigationMenu = ({ isOpen, ...restOfProps }: MobileNavigationMenuProps) => {
  const { isSpecificComponent } = usePlasmicCanvas();
  const element = <_MobileNavigationMenu isOpen={isOpen} {...restOfProps} />;

  if (isSpecificComponent(ComponentName.MobileNavigationMenu)) {
    return <MobileNavigationProvider>{element}</MobileNavigationProvider>;
  }

  return element;
};

export const MOBILE_NAVIGATION_MENU_META: ComponentMeta<MobileNavigationMenuProps> = {
  importPath: '@air/plasmic',
  parentComponentName: ComponentName.MobileNavigation,
  name: ComponentName.MobileNavigationMenu,
  props: {
    children: {
      type: 'slot',
    },
    dir: {
      displayName: 'Direction',
      type: 'choice',
      options: ['ltr', 'rtl'],
      defaultValue: 'rtl',
    },
    isOpen: {
      type: 'boolean',
      defaultValue: true,
    },
  },
};

import { AnalyticsBrowser } from '@segment/analytics-next';
import isbot from 'isbot';
import React, { useEffect, useMemo, useRef } from 'react';

import { AnalyticsContext } from '../contexts/AnalyticsContext';

interface AnalyticsProviderProps {
  children: React.ReactNode;
}

export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const analyticsRef = useRef<AnalyticsBrowser | undefined>(undefined);
  const globalPropsRef = useRef({});

  useEffect(() => {
    const analyticsKey = process.env.NEXT_PUBLIC_SEGMENT_KEY;

    // if there is no analyticsKey or analytics have already been set
    // don't reinstantiate the analyticsd
    if (!analyticsKey) {
      console.error(
        `No analytics key was provided! Missing the NEXT_PUBLIC_SEGMENT_KEY environment variable. If you're working locally, add NEXT_PUBLIC_SEGMENT_KEY to your .env.local. If this is in prod, add NEXT_PUBLIC_SEGMENT_KEY to the environment variables in Vercel.`,
      );
      return;
    }

    if (analyticsRef.current) {
      console.warn('Analytics tried to start even though it was already running');
      return;
    }

    analyticsRef.current = AnalyticsBrowser.load({ writeKey: analyticsKey });

    const userAgentString = typeof window !== 'undefined' ? window.navigator.userAgent : '';

    if (isbot(userAgentString)) {
      analyticsRef.current.identify('bot');
    }
  }, []);

  const value = useMemo(() => ({ analyticsRef, globalPropsRef }), []);

  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};

export enum ComponentName {
  Accordion = 'Accordion',
  AccordionContent = 'AccordionContent',
  AccordionHeader = 'AccordionHeader',
  AccordionItem = 'AccordionItem',
  AccordionProvider = 'AccordionProvider',
  AccordionTrigger = 'AccordionTrigger',
  AssetPosition = 'AssetPosition',
  AssetPositionProvider = 'AssetPositionProvider',
  BillingCycle = 'BillingCycle',
  BillingCycleToggle = 'BillingCycleToggle',
  BillingCycleTrigger = 'BillingCycleTrigger',
  Button = 'Button',
  ChilipiperForm = 'ChilipiperForm',
  Collapsible = 'Collapsible',
  CollapsibleContent = 'CollapsibleContent',
  CollapsibleTrigger = 'CollapsibleTrigger',
  Dialog = 'Dialog',
  DialogContent = 'DialogContent',
  DialogDescription = 'DialogDescription',
  DialogOverlay = 'DialogOverlay',
  DialogTitle = 'DialogTitle',
  DialogTrigger = 'DialogTrigger',
  ErrorMessage = 'ErrorMessage',
  Flyer = 'Flyer',
  Form = 'Form',
  FormRow = 'FormRow',
  HomeHero = 'HomeHero',
  HomeAssets = 'HomeAssets',
  Input = 'Input',
  Marquee = 'Marquee',
  MobileNavigation = 'MobileNavigation',
  MobileNavigationMenu = 'MobileNavigationMenu',
  MobileNavigationOverlay = 'MobileNavigationOverlay',
  NavigationMenu = 'NavigationMenu',
  NavigationMenuContent = 'NavigationMenuContent',
  NavigationMenuIndicator = 'NavigationMenuIndicator',
  NavigationMenuItem = 'NavigationMenuItem',
  NavigationMenuLink = 'NavigationMenuLink',
  NavigationMenuList = 'NavigationMenuList',
  NavigationMenuSub = 'NavigationMenuSub',
  NavigationMenuTrigger = 'NavigationMenuTrigger',
  NavigationMenuViewport = 'NavigationMenuViewport',
  ProgressBar = 'ProgressBar',
  RouteForm = 'RouteForm',
  SearchParamText = 'SearchParamText',
  Tabs = 'Tabs',
  TabsContent = 'TabsContent',
  TabsList = 'TabsList',
  TabsTrigger = 'TabsTrigger',
  TabsCollapsibleTrigger = 'TabsCollapsibleTrigger',
  Video = 'Video',
}

import Cookies from 'js-cookie';
import { isUndefined } from 'lodash';
import { useRouter } from 'next/router';
import { memo, useEffect, useRef } from 'react';
import { UAParser } from 'ua-parser-js';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { useTrackHideIntercomWidget } from '../hooks/useTrackHideIntercomWidget';
import { useTrackViewedPage } from '../hooks/useTrackViewedPage';
import { getUTMParameters } from '../utils/getUTMParameters';

export type AnalyticsPageRoutingProps = {
  category?: 'Blog' | 'Marketing';
};

const getReferrer = () => {
  return !!document.referrer ? new URL(document.referrer) : null;
};

/** Tracks page views for analytics */
export const AnalyticsPageRouting = memo(({ category = 'Marketing' }: AnalyticsPageRoutingProps) => {
  const router = useRouter();
  const isFirstLoad = useRef(true);
  const previousUrl = useRef<string | undefined>();
  const { setGlobalEventProps } = useAnalyticsUtilities();
  const { trackViewedPage } = useTrackViewedPage();
  const { trackHideIntercomWidget } = useTrackHideIntercomWidget();

  useEffect(() => {
    if (isFirstLoad.current) {
      // this sends a page event to intercom which is required to initialize it
      trackHideIntercomWidget();

      const parser = new UAParser();

      /**
       * Since our app is a single page app, `document.referrer` doesn't change
       * per a session. Therefore, we can utilize `document.referrer` to determine
       * the initial referrer for the user.
       */
      const initialReferrer = getReferrer();

      /**
       * The initial referrer that was set in the last 60 days is used to determine
       * the initial referrer for the user. If the initial referrer is not set, then
       * we can assume that the user is a new user.
       */
      const initialReferrer60DaysCookie = Cookies.get('initial-referrer-60-days');
      const initialReferrer60Days = initialReferrer60DaysCookie
        ? JSON.parse(initialReferrer60DaysCookie)
        : initialReferrer?.href
        ? initialReferrer.href
        : '$direct';
      const initialReferreringDomain60DaysCookie = Cookies.get('initial-referring-domain-60-days');
      const initialReferreringDomain60Days = initialReferreringDomain60DaysCookie
        ? JSON.parse(initialReferreringDomain60DaysCookie)
        : null;

      if (isUndefined(initialReferrer60DaysCookie)) {
        Cookies.set('initial-referrer-60-days', JSON.stringify(initialReferrer?.href ?? '$direct'), { expires: 60 });
      }

      if (isUndefined(initialReferreringDomain60DaysCookie)) {
        Cookies.set('initial-referring-domain-60-days', JSON.stringify(initialReferrer?.hostname ?? '$direct'), {
          expires: 60,
        });
      }

      /**
       * We need to set the utm parameters on the page event so
       * that they are available to the analytics for the entire
       * session and not just the initial page load.
       */
      const utmParams = getUTMParameters(window.location.search);
      const initialUTMParams = getUTMParameters(window.location.search, 'initial');

      /**
       * We want to track the initial UTM parameters that were
       * set when the user first visited the site.
       */
      const initialUTMParamsCookie = Cookies.get('initial-utm-params');
      const initialUTMParamsCookieObject = initialUTMParamsCookie ? JSON.parse(initialUTMParamsCookie) : {};

      if (!initialUTMParamsCookie) {
        Cookies.set('initial-utm-params', JSON.stringify(initialUTMParams), { expires: 60 });
      }

      setGlobalEventProps({
        OS: parser.getOS().name,
        screen_height: window.innerHeight,
        screen_width: window.innerWidth,
        initial_referrer_60_days: initialReferrer60Days,
        initial_referring_domain_60_days: initialReferreringDomain60Days,
        initial_referrer: initialReferrer?.href ?? '$direct',
        initial_referring_domain: initialReferrer?.hostname ?? '$direct',
        referrer: initialReferrer?.href ?? '$direct',
        referring_domain: initialReferrer?.hostname ?? '$direct',
        ...initialUTMParams,
        ...initialUTMParamsCookieObject,
        ...utmParams,
      });
    }

    const routeChangeStartHandler = () => {
      previousUrl.current = router.asPath;
    };

    const routeChangeCompleteHandler = (url: string) => {
      // this order is important, `setGlobalEventProps` must go first
      setGlobalEventProps({ current_url: url });
      trackViewedPage({
        category,
        pathname: router.asPath,
        previousPathname: isFirstLoad.current ? undefined : previousUrl.current,
        url,
      });

      isFirstLoad.current = false;
    };

    router.events.on('routeChangeStart', routeChangeStartHandler);
    router.events.on('routeChangeComplete', routeChangeCompleteHandler);

    return () => {
      router.events.off('routeChangeStart', routeChangeStartHandler);
      router.events.off('routeChangeComplete', routeChangeCompleteHandler);
    };
  }, [
    category,
    setGlobalEventProps,
    router.asPath,
    router.isReady,
    trackViewedPage,
    trackHideIntercomWidget,
    router.events,
  ]);

  return null;
});

AnalyticsPageRouting.displayName = 'AnalyticsPageRouting';

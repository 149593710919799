import { ComponentMeta } from '@plasmicapp/host';
import { motion, useReducedMotion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { ComponentName } from '../constants/componentName';

export type ProgressBarProps = {
  appearance: 'circle' | 'default';
  backgroundColor: string;
  className?: string;
  color: string;
  percent?: number;
};

export const ProgressBar = ({
  appearance,
  className,
  backgroundColor = '#E7F4F6',
  color = '#014CFF',
  percent = 30,
  ...restOfProps
}: ProgressBarProps) => {
  const [ref, inView] = useInView({ triggerOnce: true });
  const shouldReduceMotion = useReducedMotion();
  const percentage = 800 - percent * 8;

  if (appearance === 'circle') {
    return (
      <svg
        ref={ref}
        className={className}
        viewBox="0 0 286 286"
        {...restOfProps}
        style={{ transform: 'rotate(90deg) scaleY(-1)' }}
      >
        <path
          d="M15.6164 143C15.6164 213.352 72.6479 270 143 270C213.352 270 270 213.352 270 143C270 72.648 213.352 15.6165 143 15.6165C72.6479 15.6165 15.6164 72.648 15.6164 143Z"
          fill="none"
          stroke={backgroundColor}
          strokeWidth="32"
          strokeDasharray="800"
        />
        <motion.path
          animate={{ strokeDashoffset: inView ? percentage : 800 }}
          d="M15.6164 143C15.6164 213.352 72.6479 270 143 270C213.352 270 270 213.352 270 143C270 72.648 213.352 15.6165 143 15.6165C72.6479 15.6165 15.6164 72.648 15.6164 143Z"
          fill="none"
          initial={{ strokeDashoffset: 800 }}
          stroke={color}
          strokeWidth="32"
          strokeLinecap="butt"
          strokeDasharray="800"
          transition={{ duration: shouldReduceMotion ? 0 : 0.8, type: 'tween' }}
        />
      </svg>
    );
  }

  return (
    <div
      className={className}
      ref={ref}
      style={{
        backgroundColor,
        borderRadius: 999,
        color,
        overflow: 'hidden',
      }}
      {...restOfProps}
    >
      <motion.div
        animate={{ width: inView ? `${percent}%` : 0 }}
        initial={{ width: 0 }}
        transition={{ duration: shouldReduceMotion ? 0 : 0.6, type: 'tween' }}
        style={{
          backgroundColor: color,
          height: '100%',
          borderRadius: 999,
        }}
      />
    </div>
  );
};

export const PROGRESS_BAR_META: ComponentMeta<ProgressBarProps> = {
  importPath: '@air/plasmic',
  name: ComponentName.ProgressBar,
  props: {
    appearance: {
      type: 'choice',
      options: ['circle', 'default'],
      defaultValue: 'default',
    },
    backgroundColor: {
      type: 'string',
      defaultValue: '#E7F4F6',
    },
    color: {
      type: 'string',
      defaultValue: '#014CFF',
    },
    percent: {
      type: 'number',
      defaultValue: 30,
    },
  },
};

import { useCallback } from 'react';

import { trackGTMEvent } from '../gtm/trackGTMEvent';
import { useAnalyticsUtilities } from './useAnalyticsUtilities';

export type ReportPageViewParams = {
  category?: string;
  pathname: string;
  previousPathname?: string;
  url: string;
};

/**
 * this tracks page views. it should only be used at a root level.
 * don't add this to a random page individually or you'll end up with duplicate calls
 */
export const useTrackViewedPage = () => {
  const { page } = useAnalyticsUtilities();

  const trackViewedPage = useCallback(
    ({ category = 'Marketing', pathname, previousPathname, url }: ReportPageViewParams) => {
      page(category, pathname, {
        location: url,
        pathName: pathname,
        previousPathname,
      });

      trackGTMEvent({ event: 'pageview', page: url });
    },
    [page],
  );

  return { trackViewedPage };
};

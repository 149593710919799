import { ComponentMeta } from '@plasmicapp/host';
import * as RadixNavigationMenu from '@radix-ui/react-navigation-menu';
import { ReactNode } from 'react';

import { ComponentName } from '../constants/componentName';

export type NavigationMenuTriggerProps = {
  children: ReactNode;
};

export const NavigationMenuTrigger = ({ children }: NavigationMenuTriggerProps) => {
  return <RadixNavigationMenu.Trigger asChild>{children}</RadixNavigationMenu.Trigger>;
};

export const NAVIGATION_MENU_TRIGGER_META: ComponentMeta<NavigationMenuTriggerProps> = {
  importPath: '@air/plasmic',
  parentComponentName: ComponentName.NavigationMenuItem,
  name: ComponentName.NavigationMenuTrigger,
  props: {
    children: {
      type: 'slot',
    },
  },
};

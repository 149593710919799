import { ComponentMeta, registerComponent as register } from '@plasmicapp/host';
import { ComponentType } from 'react';

export type RegisterComponentParams = {
  component: ComponentType<any>;
  loader: {
    registerComponent: typeof register;
  };
  meta: ComponentMeta<any>;
};

export type RegisterComponent = (params: RegisterComponentParams) => void;

export const registerComponent: RegisterComponent = ({ component, loader, meta }) => {
  if (loader) {
    return loader.registerComponent(component, meta);
  }

  return register(component, meta);
};

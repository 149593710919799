import { CodeComponentMeta } from '@plasmicapp/host';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { ReactNode } from 'react';

import { usePlasmicCanvas } from '../hooks';

export const LINK_COMPONENT_NAME = 'Link';

export type LinkProps = Pick<NextLinkProps, 'href' | 'passHref'> & {
  className?: string;
  children: ReactNode;
  color?: string;
};

export const Link = ({ children, className, color, href, passHref }: LinkProps) => {
  const { isEditor } = usePlasmicCanvas();

  if (isEditor) {
    return (
      <a className={className} style={{ color }}>
        {children}
      </a>
    );
  }

  return (
    <NextLink href={href} passHref={passHref}>
      <a className={className} style={{ color }}>
        {children}
      </a>
    </NextLink>
  );
};

export const LINK_META: CodeComponentMeta<LinkProps> = {
  name: LINK_COMPONENT_NAME,
  importPath: '@air/plasmic',
  props: {
    children: 'slot',
    href: {
      type: 'string',
      displayName: 'URL',
      description: 'The path or URL to navigate to.',
      defaultValue: '/',
    },
    passHref: {
      type: 'boolean',
      displayName: 'Pass href to <a>',
      description: 'Forces Link to send the href property to its child. ',
      defaultValue: true,
    },
    color: {
      type: 'color',
      displayName: 'Color',
      description: 'The color of the link.',
    },
  },
};

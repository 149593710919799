import { registerComponent, RegisterComponentParams } from '@air/plasmic-core';
import { ComponentMeta } from '@plasmicapp/host';
import * as RadixSelect from '@radix-ui/react-select';

const COMPONENT_NAME = 'SelectItem';

export type SelectItemProps = Pick<RadixSelect.SelectItemProps, 'children' | 'disabled' | 'textValue' | 'value'> & {
  className?: string;
};

export const SelectItem = ({ children, className, disabled, textValue, value }: SelectItemProps) => {
  return (
    <RadixSelect.Item className={className} disabled={disabled} textValue={textValue} value={value}>
      {children}
    </RadixSelect.Item>
  );
};

const COMPONENT_META: ComponentMeta<SelectItemProps> = {
  name: COMPONENT_NAME,
  importPath: '@radix-ui/react-select',
  parentComponentName: 'SelectContent',
  props: {
    children: 'slot',
    disabled: {
      type: 'boolean',
      description: 'When true, prevents the user from interacting with the item.',
    },
    textValue: {
      type: 'string',
      description:
        'Optional text used for typeahead purposes. By default the typeahead behavior will use the .textContent of the Select.ItemText part. Use this when the content is complex, or you have non-textual content inside.',
    },
    value: {
      type: 'string',
      description: 'The value given as data when submitted with a name.',
    },
  },
};

export const registerSelectItem = ({ loader }: Pick<RegisterComponentParams, 'loader'>) => {
  return registerComponent({
    component: SelectItem,
    loader,
    meta: COMPONENT_META,
  });
};

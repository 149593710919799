import { ComponentMeta } from '@plasmicapp/host';
import * as RadixNavigationMenu from '@radix-ui/react-navigation-menu';
import { ReactNode } from 'react';

import { ComponentName } from '../constants/componentName';

export type NavigationMenuListProps = {
  children: ReactNode;
};

export const NavigationMenuList = ({ children }: NavigationMenuListProps) => {
  return <RadixNavigationMenu.List asChild>{children}</RadixNavigationMenu.List>;
};

export const NAVIGATION_MENU_LIST_META: ComponentMeta<NavigationMenuListProps> = {
  importPath: '@air/plasmic',
  parentComponentName: ComponentName.NavigationMenu,
  name: ComponentName.NavigationMenuList,
  props: {
    children: {
      type: 'slot',
    },
  },
};

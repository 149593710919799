import { ComponentMeta } from '@plasmicapp/host';
import * as RadixDialog from '@radix-ui/react-dialog';
import { ReactElement, ReactNode, useEffect, useState } from 'react';

import { ComponentName } from '../constants/componentName';
import { usePlasmicCanvas } from '../hooks/usePlasmicCanvas';

export type DialogProps = {
  className?: string;
  open?: boolean;
  overlay?: ReactNode;
  trigger?: ReactElement;
};

export const Dialog = ({ open = true, overlay, trigger }: DialogProps) => {
  const [isOpened, setIsOpened] = useState(open);
  const { isEditor } = usePlasmicCanvas();

  useEffect(() => {
    if (isEditor) {
      setIsOpened(open);
    }
  }, [open, isEditor]);

  if (isEditor) {
    return (
      <RadixDialog.Root open={isOpened}>
        {trigger}
        {overlay}
      </RadixDialog.Root>
    );
  }

  return (
    <RadixDialog.Root>
      {trigger}
      {overlay}
    </RadixDialog.Root>
  );
};

export const DIALOG_META: ComponentMeta<DialogProps> = {
  importPath: '@air/plasmic',
  name: ComponentName.Dialog,
  props: {
    open: {
      displayName: 'Open',
      type: 'boolean',
      defaultValue: false,
    },
    overlay: {
      type: 'slot',
      defaultValue: [
        {
          type: 'component',
          name: 'DialogOverlay',
          props: {
            children: [
              {
                type: 'component',
                name: 'DialogContent',
              },
            ],
          },
        },
      ],
    },
    trigger: {
      type: 'slot',
      defaultValue: [
        {
          type: 'component',
          name: 'DialogTrigger',
        },
      ],
    },
  },
};

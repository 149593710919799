import { ComponentMeta } from '@plasmicapp/host';
import * as RadixAccordion from '@radix-ui/react-accordion';
import { ReactElement, useEffect, useState } from 'react';

import { ComponentName } from '../constants/componentName';
import { usePlasmicCanvas } from '../hooks';

export type AccordionProviderProps = {
  children: ReactElement;
  componentName: string;
  className: string;
  defaultValue: string;
};

export const AccordionProvider = ({ children, className, componentName, defaultValue }: AccordionProviderProps) => {
  const [value, setValue] = useState(defaultValue);
  const { isEditor, isSpecificComponent } = usePlasmicCanvas();

  useEffect(() => {
    if (isEditor) {
      setValue(defaultValue);
    }
  }, [defaultValue, isEditor]);

  if (isSpecificComponent(componentName)) {
    return (
      <RadixAccordion.Root onValueChange={setValue} type="single" value={value}>
        <div className={className}>{children}</div>
      </RadixAccordion.Root>
    );
  }

  return children;
};

export const ACCORDION_PROVIDER_META: ComponentMeta<AccordionProviderProps> = {
  importPath: '@air/plasmic',
  name: ComponentName.AccordionProvider,
  props: {
    children: 'slot',
    componentName: {
      displayName: 'Which component to check for?',
      type: 'string',
    },
    defaultValue: 'string',
  },
};

import { ComponentMeta } from '@plasmicapp/host';
import * as RadixNavigationMenu from '@radix-ui/react-navigation-menu';
import { ReactNode } from 'react';

import { ComponentName } from '../constants/componentName';
import { usePlasmicCanvas } from '../hooks/usePlasmicCanvas';

export type NavigationMenuProps = {
  children: ReactNode;
  value?: string;
};

export const NavigationMenu = ({ children, value }: NavigationMenuProps) => {
  const { isEditor } = usePlasmicCanvas();

  if (isEditor && value) {
    return (
      <RadixNavigationMenu.Root asChild value={value}>
        {children}
      </RadixNavigationMenu.Root>
    );
  }

  return <RadixNavigationMenu.Root asChild>{children}</RadixNavigationMenu.Root>;
};

export const NAVIGATION_MENU_META: ComponentMeta<NavigationMenuProps> = {
  importPath: '@air/plasmic',
  name: ComponentName.NavigationMenu,
  props: {
    children: {
      type: 'slot',
    },
    dir: {
      displayName: 'Direction',
      type: 'choice',
      options: ['ltr', 'rtl'],
      defaultValue: 'ltf',
    },
    orientation: {
      displayName: 'Orientation',
      type: 'choice',
      options: ['horizontal', 'vertical'],
      defaultValue: 'horizontal',
    },
    value: {
      displayName: 'value',
      description: 'The controlled value of the menu item to activate.',
      type: 'string',
    },
  },
};

import { string } from 'yup';

export const chilipiperEmailValidationSchema = string()
  .email()
  .test(
    'Oops, a personal email domain. Try Air free at Air.inc',
    'Oops, a personal email domain. Try Air free at Air.inc',
    (value) =>
      !value
        ?.toLowerCase()
        ?.match(
          /[a-zA-Z0-9]{0,}([.]?[a-zA-Z0-9]{1,})[@](aol.com|comcast.net|gmail.com|gmail.co|gmail.to|gmx.com|hotmail.com|icloud.com|live.com|mac.com|mail.ck|mail.com|mailbird.com|me.com|msn.com|mymail.com|outlook.com|protonmail.com|postbox.com|rocketmail.com|sbcglobal.net|taskmonk.ai|test.com|thunderbird.com|turtlewig.com|zoho.com|yahoo.com|yandex.mail)/g,
        ),
  )
  .test(
    'Oops, a personal email domain. Try Air free at Air.inc',
    'Oops, a personal email domain. Try Air free at Air.inc',
    (value) => !value?.toLowerCase()?.match(/(info|hello)[@].*/g),
  )
  .required('Email address is required')
  .default('');

import { add } from 'date-fns';
import Cookies, { CookieAttributes } from 'js-cookie';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const REFERRAL_ID_COOKIE_NAME = 'air_referral_id';

const cookieOpts: CookieAttributes = { expires: add(new Date(), { months: 1 }) };

/**
 * On mount, check whether query param for referral exists
 * and save it to cookie in case it wasn't there before
 */
export const usePersistReferral = () => {
  const {
    query: { referral },
  } = useRouter();
  const referralCookieValue = Cookies.get(REFERRAL_ID_COOKIE_NAME);

  useEffect(() => {
    if (typeof referral === 'string' && referral && !referralCookieValue) {
      Cookies.set(REFERRAL_ID_COOKIE_NAME, referral, cookieOpts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referral]);
};

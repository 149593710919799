import { ComponentMeta } from '@plasmicapp/host';
import * as RadixNavigationMenu from '@radix-ui/react-navigation-menu';
import { ReactNode } from 'react';

import { ComponentName } from '../constants/componentName';

export type NavigationMenuLinkProps = {
  children: ReactNode;
};

export const NavigationMenuLink = ({ children }: NavigationMenuLinkProps) => {
  return <RadixNavigationMenu.Link asChild>{children}</RadixNavigationMenu.Link>;
};

export const NAVIGATION_MENU_LINK_META: ComponentMeta<NavigationMenuLinkProps> = {
  importPath: '@air/plasmic',
  parentComponentName: ComponentName.NavigationMenuItem,
  name: ComponentName.NavigationMenuLink,
  props: {
    children: {
      type: 'slot',
    },
  },
};

import { ComponentMeta } from '@plasmicapp/host';
import * as RadixNavigationMenu from '@radix-ui/react-navigation-menu';
import { ReactNode } from 'react';

import { ComponentName } from '../constants/componentName';

export type NavigationMenuItemProps = {
  children: ReactNode;
  value?: string;
};

export const NavigationMenuItem = ({ children, value }: NavigationMenuItemProps) => {
  return (
    <RadixNavigationMenu.Item asChild value={value}>
      {children}
    </RadixNavigationMenu.Item>
  );
};

export const NAVIGATION_MENU_ITEM_META: ComponentMeta<NavigationMenuItemProps> = {
  importPath: '@air/plasmic',
  parentComponentName: ComponentName.NavigationMenuList,
  name: ComponentName.NavigationMenuItem,
  props: {
    children: {
      type: 'slot',
    },
    value: {
      displayName: 'Value',
      description:
        'A unique value that associates the item with an active value when the navigation menu is controlled. This prop is managed automatically when uncontrolled.',
      type: 'string',
    },
  },
};

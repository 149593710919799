import { ComponentMeta } from '@plasmicapp/host';
import { motion } from 'framer-motion';
import { ReactNode, useEffect } from 'react';

import { ComponentName } from '../constants/componentName';
import { MobileNavigationProvider, useMobileNavigation } from '../contexts/MobileNavigationProvider';
import { usePlasmicCanvas } from '../hooks/usePlasmicCanvas';

export const FADE_ANIMATION = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

export type MobileNavigationOverlayProps = {
  children: ReactNode;
  className: string;
  isOpen: boolean;
};

export const _MobileNavigationOverlay = ({ className, isOpen }: MobileNavigationOverlayProps) => {
  const { isEditor } = usePlasmicCanvas();
  const [_mobileNavigation, setMobileNavigation] = useMobileNavigation();

  useEffect(() => {
    if (isEditor) {
      setMobileNavigation(isOpen);
    }
  }, [isEditor, isOpen, setMobileNavigation]);

  return (
    <motion.div
      animate="visible"
      className={className}
      exit="hidden"
      initial="hidden"
      key={ComponentName.MobileNavigationOverlay}
      transition={{ duration: 0.4, type: 'tween' }}
      onClick={() => setMobileNavigation(false)}
      variants={FADE_ANIMATION}
    />
  );
};

export const MobileNavigationOverlay = ({ isOpen, ...restOfProps }: MobileNavigationOverlayProps) => {
  const { isSpecificComponent } = usePlasmicCanvas();

  const element = <_MobileNavigationOverlay isOpen={isOpen} {...restOfProps} />;

  if (isSpecificComponent(ComponentName.MobileNavigationMenu)) {
    return <MobileNavigationProvider initialValue={isOpen}>{element}</MobileNavigationProvider>;
  }

  return element;
};
export const MOBILE_NAVIGATION_OVERLAY_META: ComponentMeta<MobileNavigationOverlayProps> = {
  importPath: '@air/plasmic',
  parentComponentName: ComponentName.MobileNavigation,
  name: ComponentName.MobileNavigationOverlay,
  props: {
    isOpen: {
      type: 'boolean',
      defaultValue: false,
    },
  },
};

import { ComponentMeta } from '@plasmicapp/host';
import * as RadixNavigationMenu from '@radix-ui/react-navigation-menu';
import { ReactNode } from 'react';

import { ComponentName } from '../constants/componentName';

export type NavigationMenuContentProps = {
  children: ReactNode;
};

export const NavigationMenuContent = ({ children }: NavigationMenuContentProps) => {
  return <RadixNavigationMenu.Content asChild>{children}</RadixNavigationMenu.Content>;
};

export const NAVIGATION_MENU_CONTENT_META: ComponentMeta<NavigationMenuContentProps> = {
  importPath: '@air/plasmic',
  parentComponentName: ComponentName.NavigationMenu,
  name: ComponentName.NavigationMenuContent,
  props: {
    children: {
      type: 'slot',
    },
  },
};

import { ComponentMeta } from '@plasmicapp/host';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';

export const CURRENT_DATE_COMPONENT_NAME = 'CurrentDate';

export type CurrentDateProps = {
  className?: string;
};

export const CurrentDate = ({ className }: CurrentDateProps) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => setIsMounted(true), []);

  if (!isMounted) return null;

  const formattedDate = format(new Date(), 'MMMM dd, yyyy');

  return <div className={className}>{formattedDate}</div>;
};

export const CURRENT_DATE_META: ComponentMeta<CurrentDateProps> = {
  name: CURRENT_DATE_COMPONENT_NAME,
  importPath: '@air/plasmic',
  props: {},
};

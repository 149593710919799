import { registerComponent, RegisterComponentParams } from '@air/plasmic-core';
import { ComponentMeta } from '@plasmicapp/host';
import * as RadixDialog from '@radix-ui/react-dialog';
import { forwardRef } from 'react';

const COMPONENT_NAME = 'DialogOverlay';

export type DialogOverlayProps = Pick<RadixDialog.DialogOverlayProps, 'children' | 'className'>;

export const DialogOverlay = forwardRef<HTMLDivElement, DialogOverlayProps>(({ children, className }, forwardedRef) => {
  return (
    <RadixDialog.Overlay className={className} ref={forwardedRef}>
      {children}
    </RadixDialog.Overlay>
  );
});

DialogOverlay.displayName = 'DialogOverlay';

const COMPONENT_META: ComponentMeta<DialogOverlayProps> = {
  name: COMPONENT_NAME,
  importPath: '@radix-ui/react-dialog',
  parentComponentName: 'Dialog',
  props: {
    children: {
      type: 'slot',
      defaultValue: [
        {
          type: 'component',
          name: 'DialogContent',
        },
      ],
    },
  },
  defaultStyles: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
};

export const registerDialogOverlay = ({ loader }: Pick<RegisterComponentParams, 'loader'>) => {
  return registerComponent({
    component: DialogOverlay,
    loader,
    meta: COMPONENT_META,
  });
};

import { ComponentMeta } from '@plasmicapp/host';
import { ReactNode } from 'react';

import { ComponentName } from '../constants/componentName';
import { BillingCycleType, useBillingCycle } from '../hooks/useBillingCycle';

export type BillingCycleToggleProps = {
  children: ReactNode;
  type: BillingCycleType;
};

export const BillingCycleToggle = ({ children, type }: BillingCycleToggleProps) => {
  const [billingCycle] = useBillingCycle();

  if (type !== billingCycle) return null;

  return <>{children}</>;
};

export const BILLING_CYCLE_TOGGLE_META: ComponentMeta<BillingCycleToggleProps> = {
  importPath: '@air/plasmic',
  name: ComponentName.BillingCycleToggle,
  parentComponentName: ComponentName.BillingCycle,
  props: {
    children: {
      type: 'slot',
    },
    type: {
      displayName: 'Show for',
      type: 'choice',
      options: ['annual', 'monthly'],
    },
  },
};

import { ComponentMeta } from '@plasmicapp/host';
import classNames from 'classnames';
import { ReactNode } from 'react';

import { ComponentName } from '../../constants/componentName';
import { PLASMIC_DEFAULT_CLASS_ALL, PLASMIC_DEFAULT_CLASS_DIV } from '../../constants/plasmicDefaultClasses';

export type FormRowProps = {
  children: ReactNode;
  className?: string;
};

export const FormRow = ({ children, className }: FormRowProps) => {
  return (
    <div className={classNames('air-form__row', PLASMIC_DEFAULT_CLASS_ALL, PLASMIC_DEFAULT_CLASS_DIV, className)}>
      {children}
    </div>
  );
};

export const FORM_ROW_META: ComponentMeta<FormRowProps> = {
  importPath: '@air/plasmic',
  parentComponentName: ComponentName.Form,
  name: ComponentName.FormRow,
  props: {
    children: {
      type: 'slot',
    },
  },
  defaultStyles: {
    display: 'flex',
    flexDirection: 'row',
  },
};

import { ComponentMeta } from '@plasmicapp/host';
import * as RadixDialog from '@radix-ui/react-dialog';
import { ReactNode } from 'react';

import { ComponentName } from '../constants/componentName';
import { usePlasmicCanvas } from '../hooks/usePlasmicCanvas';

export type DialogContentProps = {
  children: ReactNode;
};

export const DialogContent = ({ children }: DialogContentProps) => {
  const { isSpecificComponent } = usePlasmicCanvas();

  const element = <RadixDialog.Content asChild>{children}</RadixDialog.Content>;

  if (isSpecificComponent(ComponentName.DialogContent)) {
    return <RadixDialog.Root>{element}</RadixDialog.Root>;
  }

  return element;
};

export const DIALOG_CONTENT_META: ComponentMeta<DialogContentProps> = {
  importPath: '@air/plasmic',
  parentComponentName: ComponentName.DialogOverlay,
  name: ComponentName.DialogContent,
  isAttachment: true,
  props: {
    children: {
      type: 'slot',
    },
  },
  defaultStyles: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: '#FFFFFF',
    transform: 'translate(-50%, -50%)',
  },
};

import { GlobalContextMeta, registerGlobalContext as register } from '@plasmicapp/host';
import { ComponentType } from 'react';

export type RegisterGlobalContextParams = {
  component: ComponentType<any>;
  loader: {
    registerGlobalContext: typeof register;
  };
  meta: GlobalContextMeta<any>;
};

export type RegisterGlobalContext = (params: RegisterGlobalContextParams) => void;

export const registerGlobalContext: RegisterGlobalContext = ({ component, loader, meta }) => {
  if (loader) {
    return loader.registerGlobalContext(component, meta);
  }

  return register(component, meta);
};

import { createContext, Dispatch, ReactNode, Reducer, useContext, useReducer } from 'react';

type State = {
  id: string | number;
  width: number;
  x: number;
  y: number;
};

type Action = { type: 'add'; values: State } | { type: 'update'; values: State };

const reducer: Reducer<State[], Action> = (state, action) => {
  switch (action.type) {
    case 'add':
      if (state.filter(({ id }) => id === action.values.id).length > 0) {
        return state;
      }

      return [...state, action.values];
    case 'update':
      return state.map((s) => (s.id === action.values.id ? action.values : s));
    default:
      return state;
  }
};

const AssetPositionContext = createContext<{ assetsPos?: State[]; dispatch: Dispatch<Action> } | undefined>(undefined);

export const AssetPositionProvider = ({ children, value = [] }: { children: ReactNode; value?: State[] }) => {
  const [state, dispatch] = useReducer(reducer, value);
  return (
    <AssetPositionContext.Provider value={{ assetsPos: state, dispatch }}>{children}</AssetPositionContext.Provider>
  );
};

export const useAssetPosition = () => {
  const context = useContext(AssetPositionContext);

  if (context === undefined) {
    throw new Error('AssetPositionContext called outside of AssetPositionProviderProvider');
  }

  const addAssetPos = (values: State) => {
    context.dispatch({ type: 'add', values });
  };

  const updateAssetPos = (values: State) => {
    context.dispatch({ type: 'update', values });
  };

  return {
    addAssetPos,
    assetsPos: context.assetsPos,
    updateAssetPos,
  };
};

import { useCallback } from 'react';

import { useAnalyticsUtilities } from './useAnalyticsUtilities';

export const useTrackClickedLeadFormSubmit11Plus = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedLeadFormSubmit11Plus = useCallback(
    (params: any) => {
      track('Lead Form Submit (11+)', params);
    },
    [track],
  );

  return { trackClickedLeadFormSubmit11Plus };
};

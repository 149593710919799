import { ErrorMessage as ReactHookErrorMessage } from '@hookform/error-message';
import { ComponentMeta, PlasmicCanvasContext } from '@plasmicapp/host';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { ComponentName } from '../../constants/componentName';

export type ReactHookFormErrorMessageProps = {
  className?: string;
  name: string;
};

export const ReactHookFormErrorMessage = forwardRef<HTMLDivElement, ReactHookFormErrorMessageProps>(
  ({ className, name }, ref) => {
    const {
      formState: { errors },
    } = useFormContext();

    return (
      <ReactHookErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <div className={className} ref={ref} role="alert">
            {message}
          </div>
        )}
      />
    );
  },
);

ReactHookFormErrorMessage.displayName = 'ReactHookFormErrorMessage';

export type ErrorMessageProps = ReactHookFormErrorMessageProps & {
  showErrorMessage?: boolean;
};

export const ErrorMessage = forwardRef<HTMLDivElement, ErrorMessageProps>(
  ({ className, name, showErrorMessage }, ref) => {
    const [isMounted, setIsMounted] = useState(false);
    const isEditor = useContext(PlasmicCanvasContext);

    useEffect(() => {
      setIsMounted(true);
    }, []);

    if (!isMounted) return null;

    if (!isEditor) {
      return <ReactHookFormErrorMessage className={className} name={name} />;
    }

    if (!showErrorMessage) {
      return null;
    }

    return (
      <div className={className} ref={ref} role="alert">
        Error message
      </div>
    );
  },
);

ErrorMessage.displayName = 'ErrorMessage';

export const ERROR_MESSAGE_META: ComponentMeta<ErrorMessageProps> = {
  importPath: '@air/plasmic',
  name: ComponentName.ErrorMessage,
  props: {
    name: {
      displayName: 'Name',
      type: 'string',
    },
    showErrorMessage: {
      displayName: 'Show error message',
      type: 'boolean',
      defaultValue: false,
    },
  },
  defaultStyles: {
    position: 'absolute',
    top: '100%',
    right: 'auto',
    bottom: 'auto',
    left: 0,
    fontFamily: 'Inter',
    fontSize: '8px',
    color: '#D23939',
  },
};

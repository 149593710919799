import { ComponentMeta } from '@plasmicapp/host';
import * as RadixNavigationMenu from '@radix-ui/react-navigation-menu';
import { ReactNode } from 'react';

import { ComponentName } from '../constants/componentName';

export type NavigationMenuSubProps = {
  children: ReactNode;
};

export const NavigationMenuSub = ({ children }: NavigationMenuSubProps) => {
  return <RadixNavigationMenu.Sub asChild>{children}</RadixNavigationMenu.Sub>;
};

export const NAVIGATION_MENU_SUB_META: ComponentMeta<NavigationMenuSubProps> = {
  importPath: '@air/plasmic',
  parentComponentName: ComponentName.NavigationMenuContent,
  name: ComponentName.NavigationMenuSub,
  props: {
    children: {
      type: 'slot',
    },
    orientation: {
      displayName: 'Orientation',
      type: 'choice',
      options: ['horizontal', 'vertical'],
      defaultValue: 'horizontal',
    },
  },
};

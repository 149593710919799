import { useCallback } from 'react';

import { useAnalyticsUtilities } from './useAnalyticsUtilities';

export const useTrackClickedLeadFormSubmit = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedLeadFormSubmit = useCallback(
    (params: any) => {
      track('Lead Form Submit', params);
    },
    [track],
  );

  return { trackClickedLeadFormSubmit };
};

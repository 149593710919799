import { registerComponent, RegisterComponentParams } from '@air/plasmic-core';
import { ComponentMeta } from '@plasmicapp/host';
import * as RadixSelect from '@radix-ui/react-select';
import { ReactNode } from 'react';

const COMPONENT_NAME = 'Select';

export type SelectProps = Pick<RadixSelect.SelectProps, 'defaultValue' | 'dir' | 'name'> & {
  content: ReactNode;
  trigger: ReactNode;
};

export const Select = ({ content, defaultValue, dir, name, trigger }: SelectProps) => {
  return (
    <RadixSelect.Root defaultValue={defaultValue} dir={dir} name={name}>
      {trigger}
      {content}
    </RadixSelect.Root>
  );
};

const COMPONENT_META: ComponentMeta<SelectProps> = {
  name: COMPONENT_NAME,
  importPath: '@radix-ui/react-select',
  props: {
    content: 'slot',
    trigger: 'slot',
    defaultValue: {
      type: 'string',
      description:
        'The value of the select when initially rendered. Use when you do not need to control the state of the select.',
    },
    dir: {
      type: 'choice',
      options: ['ltr', 'rtl'],
      description:
        'The reading direction of the select when applicable. If omitted, assumes LTR (left-to-right) reading mode.',
      defaultValue: 'ltr',
    },
    name: {
      type: 'string',
      description: 'The name of the select. Submitted with its owning form as part of a name/value pair.',
    },
  },
};

export const registerSelect = ({ loader }: Pick<RegisterComponentParams, 'loader'>) => {
  return registerComponent({
    component: Select,
    loader,
    meta: COMPONENT_META,
  });
};

import { registerComponent, RegisterComponentParams } from '@air/plasmic-core';
import { ComponentMeta } from '@plasmicapp/host';
import { ReactNode, useState } from 'react';
import { animated, useSpring } from 'react-spring';

export const STIFF_SPRING_CONFIG = {
  mass: 1,
  tension: 210,
  friction: 20,
};

const COMPONENT_NAME = 'CircleVideoPreview';

export type CircleVideoPreviewProps = {
  height?: string;
  width?: string;
  className: string;
  targetBorderRadius?: number;
  previewChildren?: ReactNode;
  children?: ReactNode;
};

export const CircleVideoPreview = ({
  height = '45vw',
  width = '80vw',
  targetBorderRadius = 4,
  className,
  previewChildren,
  children,
}: CircleVideoPreviewProps) => {
  const [open, setOpen] = useState(false);
  const [styles, api] = useSpring(() => ({
    config: STIFF_SPRING_CONFIG,
    from: {
      height,
      width: height,
      borderRadius: '50%',
    },
  }));

  const clickHandler = () => {
    if (!open) {
      setOpen(true);
      return api.start({ width, borderRadius: `${targetBorderRadius}px` });
    }
  };

  return (
    <animated.div
      onClick={clickHandler}
      className={className}
      style={{
        overflow: 'hidden',
        position: 'relative',
        ...styles,
      }}
    >
      <div
        style={{
          /**
           * positioning inside animation.div
           */
          position: 'absolute',
          top: 0,
          left: '50%',
          width: '100%',
          height: '100%',
          transformOrigin: 'center',
          transform: 'scale(100%) translateX(-50%)',
          cursor: 'pointer',
          /**
           * transition to video
           */
          transition: 'opacity .2s ease',
          opacity: open ? 0 : 1,
        }}
      >
        {previewChildren}
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: '50%',
          width: '100%',
          height: '100%',
          transformOrigin: 'center',
          transform: 'scale(100%) translateX(-50%)',
          visibility: open ? 'unset' : 'hidden',
        }}
      >
        {open && children}
      </div>
    </animated.div>
  );
};

const COMPONENT_META: ComponentMeta<CircleVideoPreviewProps> = {
  name: COMPONENT_NAME,
  importPath: '@air/plasmic',
  props: {
    children: 'slot',
    previewChildren: 'slot',
    targetBorderRadius: {
      type: 'number',
      displayName: 'Corner radius (in px)',
    },
    height: {
      type: 'string',
      displayName: 'Height',
    },
    width: {
      type: 'string',
      displayName: 'Width (when expanded)',
    },
  },
};

export const registerCircleVideoPreview = ({ loader }: Pick<RegisterComponentParams, 'loader'>) => {
  return registerComponent({
    component: CircleVideoPreview,
    loader,
    meta: COMPONENT_META,
  });
};
